import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, withApollo, graphql } from 'react-apollo'
import { useState } from 'react'
import Loader from "../../../../../Material/Loader"
import { CardHeader, Card, CardBody, CardTitle, Button, Row, Col, Popover, PopoverBody } from 'reactstrap'

const AllArgyleAccountsQuery = gql`query AllArgyleAccounts($argyleUserId: String){
    allArgyleAccounts(argyleUserId: $argyleUserId){
        accountId
        employers
        userId
        source
        connectionStatus
        connectionCode
    }
}`


export function ArgyleAccountPopOver({ isOpen, toggle, target, employer, status, statusCode }) {
    return (
        <Popover placement="bottom" target={target} isOpen={isOpen} toggle={toggle}>
            <PopoverBody>
                {/* Show information about status, account name and source. One data point per line */}
                <Row>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>Account Name</span>
                        <span>{employer || ""}</span>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>Status</span>
                        <span>{status ? status.toUpperCase() : ""}</span>
                    </Col>
                    {statusCode &&
                        <Col xs={12} className="d-flex justify-content-between">
                            <span>Source</span>
                            <span>{statusCode}</span>
                        </Col>
                    }
                </Row>
            </PopoverBody>
        </Popover>
    )
}

function RiskIntegrationDetails(props) {
    const [popoverOpen, setPopoverOpen] = useState("")
    const [selectedAccount, setSelectedAccount] = useState(null)
    if (props.loading) return <Loader />
    return (
        <Row>
            <Col xs={12} className="d-flex justify-content-start"><h4>Integrations</h4></Col>
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="argyle-integration" id="argyle-integration">
                        <Card>
                            <CardHeader>
                                <img id="argyle-logo" src="https://argyle.com/images/logos/argyle-logo.svg" />
                            </CardHeader>
                            <CardBody>
                                <CardTitle tag="h5">Argyle Connected Accounts</CardTitle>
                                {popoverOpen && selectedAccount &&
                                    <ArgyleAccountPopOver
                                        isOpen={popoverOpen}
                                        toggle={() => setPopoverOpen("")}
                                        target={selectedAccount && selectedAccount.accountId ? "accountID" + selectedAccount.accountId.replace("-", "") : "argyle-integration"}
                                        employer={selectedAccount ? selectedAccount.employers.join().toUpperCase() : ""}
                                        status={selectedAccount ? selectedAccount.connectionStatus : ""}
                                        statusCode={selectedAccount ? selectedAccount.connectionCode : ""}
                                    />
                                }
                                {props.allArgyleAccounts && props.allArgyleAccounts.map((account, index) => {
                                    return (
                                        <Button
                                            className={`${account.connectionStatus != "connected" ? "btn-danger" : "success"}` + " mb-2"}
                                            id={account.accountId ? "accountID" + account.accountId.replace("-", "") : ""}
                                            onClick={() => { setSelectedAccount(account); setPopoverOpen(popoverOpen ? "" : account.source) }}
                                        >
                                            {account.employers && account.employers.length ? account.employers.join().toUpperCase() : ""}
                                        </Button>
                                    )
                                })}
                                {!(props.allArgyleAccounts && props.allArgyleAccounts.length && !props.loading) && <span>No connected accounts</span>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}

export default compose(
    withApollo,
    graphql(AllArgyleAccountsQuery, {
        options: ({ argyleUserId }) => ({ variables: { argyleUserId }, fetchPolicy: 'cache-and-network' }),
        props({ data: { allArgyleAccounts, loading, variables } }) {
            return {
                allArgyleAccounts, loading, variables
            }
        }
    }),
)(RiskIntegrationDetails)