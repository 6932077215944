import React, { Component } from 'react'
import { compose, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Label, Alert,
    Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import moment from 'moment'
import DateTimePicker from "../../Material/DateTimePicker"
import "./PaymentScheduleModal.css"

const CreatePaymentSchedule = gql`
mutation createPaymentSchedule($input: CreatePaymentScheduleMutationInput!){
    createPaymentSchedule(input:$input){
    ok
    errors{
      messages
    }
}
} `

const MINIMUM_SCHEDULE_DATETIME = moment().tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)
const MAXIMUM_SCHEDULE_DATETIME = moment().tz("America/New_York").add(5, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL)
class NewPaymentSchedule extends Component {
    constructor(props) {
        super(props)

        this.state = {
            input: {
                "amountToCharge": 0,
                "scheduleDatetime": MINIMUM_SCHEDULE_DATETIME,
                "maxRetries": 0,
                "notes": "",
            },
            error: null,
            loading: false
        }
    }

    updateInput = (e) => {
        if (this.props.driverId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["driverId"] = this.props.driverId
            input[name] = value
            this.setState({ input: input })
        }
    }
    createPaymentSchedule = (e) => {
        try {
            this.setState({ loading: true })
            let mutationInput = this.state.input
            let scheduleDatetime = new Date(mutationInput["scheduleDatetime"])
            mutationInput["scheduleDatetimeInUtc"] = Math.floor(scheduleDatetime.getTime() / 1000)
            delete mutationInput["scheduleDatetime"]
            this.props.client.mutate({
                mutation: CreatePaymentSchedule,
                variables: { input: mutationInput },
            }).then((result) => {
                if (result && result.data && result.data.createPaymentSchedule && result.data.createPaymentSchedule.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                } else {
                    let error = result && result.data && result.data.createPaymentSchedule && result.data.createPaymentSchedule.errors && result.data.createPaymentSchedule.errors.length > 0
                        && result.data.createPaymentSchedule.errors.map(i => { return i.messages.join(' ') }).join(' ')
                    if (!error) {
                        error = "An error occurred, could not complete request."
                    }
                    this.setState({ error: error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                let error = "An error has occured"
                this.setState({ error: error, loading: false })
            })
        }
        catch (err) {
            let error = "An error has occured"
            this.setState({ error: error, loading: false })
        }

    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="payment-schedule-modal">
                    <ModalHeader toggle={this.toggle}>Schedule a Payment</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}

                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="amountToCharge">Amount to charge</Label>
                                    <Input type={"number"} name="amountToCharge" id="amountToCharge" onChange={this.updateInput} required placeholder="Enter amount to charge" />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="scheduleDatetime">Date & time to charge</Label>
                                    <DateTimePicker type="datetime-local" min={MINIMUM_SCHEDULE_DATETIME} max={MAXIMUM_SCHEDULE_DATETIME}
                                        name="scheduleDatetime" id="scheduleDatetime" value={new Date()}
                                        placeholder="Select schedule datetime" setError={(error) => this.setState({ error })}
                                        updateInput={(name, value) => this.setState({ input: { ...this.state.input, driverId: this.props.driverId, [name]: value } })}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col xs={12}>
                                <FormGroup>
                                    <Label for="maxRetries">How many tries (Schedule will be canceled if payment fails after the attempts)</Label>
                                    <Input type="select" name="maxRetries" value={this.state.input.maxRetries.toString()} id="maxRetries" onChange={this.updateInput} placeholder="Select Reason">
                                        <option value=""></option>
                                        {[0, 1, 2, 3, 4, 5].map((maxRetry, i) =>
                                            <option key={i} value={maxRetry}>{maxRetry} time(s)</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col> */}
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="notes">Payment schedule notes</Label>
                                    <Input type={"text"} name="notes" id="notes" onChange={this.updateInput} defaultValue={this.state.input.notes} placeholder="Notes" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="payment-schedule-modal-primary-btn" onClick={this.createPaymentSchedule} disabled={this.state.loading || (this.state.input.amountToCharge == 0 || this.state.input.amountToCharge < 1) || this.state.input.scheduleDatetime == null}>{this.state.loading ? "Loading..." : "Schedule Payment"}</Button>{' '}
                        <Button className="payment-schedule-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
)(NewPaymentSchedule)