export default function updateFilters(filters, configurations, history, location, setFilterValues) {
    let visibleFilters = configurations.filter(item => item.showFilter).map(item => item.name)
    let newFilterValues = {}
    Object.keys(filters).map(key => {
        if (visibleFilters.includes(key) || key === "searchTerm")
            newFilterValues[key] = filters[key]
    })
    const queryString = new URLSearchParams(newFilterValues).toString()
    history.push({ hash: location.hash, search: queryString })
    setFilterValues({ ...filters })
}