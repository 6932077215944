import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo, graphql } from 'react-apollo/index'
import { Col, Row, Input, Button } from 'reactstrap'
import ListingFilters from "../Material/ListingFilters"
import { debounce } from 'lodash'
import renderEnumToInt from "../Functions/renderEnumToInt"
import toTitleCase from "../Functions/toTitleCase"
import { HasPermissionsQuery } from "../Functions/querys"
import ManagePlatesModal from "./ManagePlatesModal/ManagePlatesModal"
import UpdatePolicyModal from "./UpdatePolicyModal"
import updateFilters from "../Functions/updateFilters"
import getFilterValuesFromQueryString from "../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../Functions/handleConfigurationChange"

const CarStages = gql`query CarStages{
    carStages: __type(name: "CarStage") {
        states: enumValues {
            name
            description
        }
    }
  }`


const AllCarLocationsQuery = gql`
  query AllCarLocations{
    allCarLocations{
        edges{
            node{
                name
                id
                pk
                carsCount
                carStageCount{
                    stage
                    carsCount
                }
            }
        }
    }
  }
`

const CarsWithoutLocationCount = gql`query CarsWithoutLocationCount{
    carsWithoutLocationCount{
        summary
        details {
            name
            value
        }
    }
}`
const AllCarModels = gql`
  {
    optionsList: allCarModels(orderBy: ["-make"]){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`
const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 11){
      edges {
        node {
          id
          name
          color
        }
      }
    }
}`

class CarsListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "select", name: "selectedLocations", title: "Cars Location ", options: [], placeholder: "Filter By Car locations", showFilter: true, isMulti: true },
            { type: "select", name: "selectedStages", title: "Cars Stages ", options: [], placeholder: "Filter By Car Stages", showFilter: true, isMulti: true },
            { type: "select", name: "carTags", title: "Car Tags", optionsQuery: TagsQuery, placeholder: "Filter By Car Tags", valueSelector: "name", labelSelector: "name", isMulti: true, showFilter: true },
            { type: "select", name: "carModelIds", title: "Car Model", optionsQuery: AllCarModels, placeholder: "Filter By Car Model", valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: true },
            { type: "select", name: "carModelNames", title: "Car Model Name", optionsQuery: AllCarModels, placeholder: "Filter By Car Model Name", valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: true },
            {
                type: "boolean", name: "isReady", title: "Is Ready", optionOne: "YES",
                optionTwo: "NO", showFilter: true
            },
            {
                type: "boolean", name: "hasDeliveryDate", title: "Has Delivery Date", optionOne: "YES",
                optionTwo: "NO", showFilter: true
            },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = debounce((searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }, 500)
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    if (filterConfigs.name === 'selectedLocations' || filterConfigs.name === 'selectedStages') {
                        value = value.map(item => options.find(option => option.value == item).label.props.children[0])
                    } else {

                        value = value.map(item => options.find(option => option.value == item).label)
                    }

                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations()
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0)
                        if (filter['name'] === "carTags")
                            options = [{ value: "false", label: "Without Tags" }, ...result.data.optionsList.edges.map(tag => ({ value: tag.node.name, label: tag.node.name }))]
                        else if (filter['name'] === "carModelNames") {
                            options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node.name, label: toTitleCase(options.node.make) + " " + options.node.name })).filter((obj, index, arr) => arr.findIndex(model => model.value === obj.value) === index)
                        }
                        else
                            options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: filter['name'] === 'carModelIds' ? toTitleCase(options.node.make) + " " + options.node.name + (options.node.series ? " | " + options.node.series : "") + (options.node.groupType ? " | " + options.node.groupType.name : " | No Group Found!") : options.node[filter.labelSelector] }))
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        this.setConfigurations()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    render() {
        let configurations = this.state.configurations
        if (configurations && this.props.allCarLocations !== null && this.props.carsWithoutLocationCount !== null) {
            let locationOption = this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 ?
                [{ value: "None", label: <>{"Without Location"}&nbsp;<small className="float-right">{this.props.carsWithoutLocationCount && this.props.carsWithoutLocationCount.summary ? this.props.carsWithoutLocationCount.summary : "0"} CARS</small></> }]
                    .concat(this.props.allCarLocations.edges.map(item => ({ value: item.node.id, label: (<>{item.node.name}&nbsp;<small className="float-right">{item.node.carsCount} CARS</small></>) }))) :
                [{ value: "None", label: <>{"Without Location"}&nbsp;<small className="float-right">{this.props.carsWithoutLocationCount && this.props.carsWithoutLocationCount.summary ? this.props.carsWithoutLocationCount.summary : "0"} CARS</small></> }]
            let carLocationFilterIndex = configurations.map(filter => filter.name).findIndex(name => name === "selectedLocations")
            if (carLocationFilterIndex !== -1)
                configurations[carLocationFilterIndex]['options'] = locationOption
        }

        let carCounts = []
        let selectedLocs = []

        if (this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 && this.props.filterValues.selectedLocations && this.props.filterValues.selectedLocations.length > 0)
            selectedLocs = this.props.allCarLocations.edges.filter(loc => this.props.filterValues.selectedLocations.includes(loc.node.id))
        else if (this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0)
            selectedLocs = this.props.allCarLocations.edges

        if (selectedLocs) {
            selectedLocs.map(loc => {
                loc.node && loc.node.carStageCount && loc.node.carStageCount.length > 0 && loc.node.carStageCount.map(stageCount => {
                    if (carCounts[stageCount.stage])
                        carCounts[stageCount.stage] = carCounts[stageCount.stage] + stageCount.carsCount
                    else
                        carCounts[stageCount.stage] = stageCount.carsCount
                })
            })
        }
        if ((this.props.filterValues.selectedLocations && this.props.filterValues.selectedLocations.includes("None") && this.props.carsWithoutLocationCount) || (this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 && !this.props.filterValues.selectedLocations)) {
            this.props.carsWithoutLocationCount && this.props.carsWithoutLocationCount.details && this.props.carsWithoutLocationCount.details.length > 0 && this.props.carsWithoutLocationCount.details.map(stageCount => {
                if (carCounts[parseInt(stageCount.name)])
                    carCounts[parseInt(stageCount.name)] = carCounts[parseInt(stageCount.name)] + parseInt(stageCount.value)
                else
                    carCounts[parseInt(stageCount.name)] = parseInt(stageCount.value)
            })
        }
        if (configurations && this.props.carStages) {
            let stageOptions = []
            stageOptions = this.props.carStages &&
                this.props.carStages.states &&
                this.props.carStages.states.length > 0 &&
                this.props.carStages.states.map(item => ({ value: renderEnumToInt(item.name), label: (<>{"Stage " + renderEnumToInt(item.name) + " - "}<span style={{ textTransform: "capitalize" }}>{item.description}</span>&nbsp;<small className="float-right">{carCounts && carCounts[renderEnumToInt(item.name)] ? carCounts[renderEnumToInt(item.name)] : "0"} CARS</small></>) }))
            let selectedStagesFilterIndex = configurations.map(filter => filter.name).findIndex(name => name === "selectedStages")
            if (selectedStagesFilterIndex !== -1)
                configurations[selectedStagesFilterIndex]['options'] = stageOptions
        }
        return (
            <Row className="mb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={configurations}
                    />}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={8} className="text-right mt-2">
                    {this.state.openModal && this.state.openModal === "managePlatesModal" && <ManagePlatesModal handleClose={() => this.toggleModal("managePlatesModal")} open={this.state.openModal === "managePlatesModal"} />}
                    {this.state.openModal && this.state.openModal === "updatePolicyModal" && <UpdatePolicyModal handleClose={() => this.toggleModal("updatePolicyModal")} open={this.state.openModal === "updatePolicyModal"} />}
                    {this.props.hasPermissions && this.props.hasPermissions.includes("change_plate") && <>
                        <a className="driver-list-button" onClick={() => this.toggleModal("managePlatesModal")}>
                            <i className={"fa fa-exchange"}></i> Manage Plates
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp; </>
                    }
                    {this.props.hasPermissions && this.props.hasPermissions.includes("change_carpolicy") && <>
                        <a className="driver-list-button" onClick={() => this.toggleModal("updatePolicyModal")}>
                            <i className={"fa fa-paper-plane-o"}></i> Update Policy
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp; </>
                    }
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarsWithoutLocationCount, { props({ data: { carsWithoutLocationLoading, carsWithoutLocationCount } }) { return { carsWithoutLocationLoading, carsWithoutLocationCount } } }),
    graphql(AllCarLocationsQuery, { options: { fetchPolicy: 'cache-first' }, props({ data: { allCarLocationsLoading, allCarLocations } }) { return { allCarLocationsLoading, allCarLocations } } }),
    graphql(CarStages, { props({ data: { carStagesLoading, carStages } }) { return { carStagesLoading, carStages } } }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["change_plate", "change_carpolicy"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
)(CarsListHeader)