import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import { Col, Row, Input } from 'reactstrap'
import { debounce } from 'lodash'
import ListingFilters from "../Material/ListingFilters"
import toTitleCase from "../Functions/toTitleCase"
import AddPricingModal from "./AddPricingModal"
import updateFilters from "../Functions/updateFilters"
import getFilterValuesFromQueryString from "../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../Functions/handleConfigurationChange"

const AllCarModelGroupTypes = gql`
  {
    optionsList: allCarModelGroupTypes{
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const AllCarModels = gql`
  {
    optionsList: allCarModels(orderBy: ["-make"]){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`


const BulkUpdatePricingTemplateMutation = gql`
  mutation bulkUpdatePricingTemplate($input: BulkUpdatePricingTemplateMutationInput!) {
    bulkUpdatePricingTemplate(input: $input) {
      ok
      errors {
        messages
      }
    }
  }
`

class PricingListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "select", name: "modelGroupTypeId", title: "Car Model Groups", optionsQuery: AllCarModelGroupTypes, placeholder: "Filter By Car Model Groups", valueSelector: "id", labelSelector: "name", showFilter: true },
            { type: "select", name: "modelTypeId", title: "Car Model", optionsQuery: AllCarModels, placeholder: "Filter By Car Models", valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: true },
            { type: "boolean", name: "isActive", title: "Active Pricing", optionOne: "YES", optionTwo: "NO", showFilter: true },
            { type: "boolean", name: "isDefault", title: "Default Pricing", optionOne: "YES", optionTwo: "NO", showFilter: true },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = debounce((searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }, 500)
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item) && options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations()
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(option => option.node && ({
                            value: option.node[filter.valueSelector], label: filter.name === "modelTypeId" ? toTitleCase(option.node.make) + " " + option.node.name + (option.node.series ? " | " + option.node.series : "") + (option.node.groupType ? " | " + option.node.groupType.name : " | No Group Found!") : option.node[filter.labelSelector]
                        }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        this.setConfigurations()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    handleUpdatePricing = (name, value) => {
        let selectedPricing = this.props.selectedPricing
        let input = {
            pricingTemplates: selectedPricing,
            [name]: value
        }
        this.props.client
            .mutate({
                mutation: BulkUpdatePricingTemplateMutation,
                variables: { input },
            })
            .then((result) => {
                if (result.data && result.data.bulkUpdatePricingTemplate && result.data.bulkUpdatePricingTemplate.ok) {
                    this.props.refetchQuery()
                    this.props.resetSelectedPricing()
                }
            })
            .catch((err) => {
                this.setState({ loading: false, error: err.message })
            })
    }
    render() {
        return (
            <Row className="pb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "addPricingModal" && <AddPricingModal isOpen={this.state.openModal === "addPricingModal"} toggleModal={() => this.toggleModal("addPricingModal")} refetch={() => this.props.refetchQuery()} />}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={8} className="text-right mt-2">
                    {this.props.selectedPricing && this.props.selectedPricing.length > 0 && <>
                        <a id="viewFilters" className="driver-list-button" onClick={() => this.handleUpdatePricing("visibleToCustomers", false)}>
                            Not Visible To Customer | <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a id="viewFilters" className="driver-list-button" onClick={() => this.handleUpdatePricing("visibleToCustomers", true)}>
                            Visible To Customer | <i className="fa fa-eye" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a id="viewFilters" className="driver-list-button" onClick={() => this.handleUpdatePricing("isActive", false)}>
                            Set Inactive | <i className="fa fa-toggle-off" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a id="viewFilters" className="driver-list-button" onClick={() => this.handleUpdatePricing("isActive", true)}>
                            Set Active | <i className="fa fa-toggle-on" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </>}

                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("addPricingModal")}>
                        Add New Price | <i className="fa fa-plus" aria-hidden="true"></i>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
)(PricingListHeader)
