import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Label, Alert,
    Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import moment from 'moment'
import { EnumQuery } from '../../Functions/querys'

const CreatePaymentPlan = gql`
mutation createPaymentPlan($input: CreatePaymentPlanInput!){
    createPaymentPlan(input:$input){
    ok
    errors{
      messages
    }
}
} `

class NewPaydown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: { "paydownAmount": 0, "paydownOriginalBalance": 0, "paydownFrequency": 7, "paydownStartDate": moment().tz("America/New_York").format(moment.HTML5_FMT.DATE), "paydownNotes": "", "reason": "" },
            error: null,
            loading: false
        }
    }
    updateInput = (e) => {
        if (this.props.driverId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["driverId"] = this.props.driverId
            input[name] = value
            this.setState({ input: input })
        }
    }
    createPaymentPlan = (e) => {
        try {
            this.setState({ loading: true })
            let input = this.state.input
            this.props.client.mutate({
                mutation: CreatePaymentPlan,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.createPaymentPlan && result.data.createPaymentPlan.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                } else {
                    let error = "An error occurred, could not complete request."
                    this.setState({ error: error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                let error = "An error has occured"
                this.setState({ error: error, loading: false })
            })
        }
        catch (err) {
            let error = "An error has occured"
            this.setState({ error: error, loading: false })
        }

    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open}>
                    <ModalHeader toggle={this.toggle}>Create New Paydown</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="paydownAmount">Enter weekly paydown amount</Label>
                                    <Input type={"number"} name="paydownAmount" id="paydownAmount" onChange={this.updateInput} defaultValue={this.state.input.paydownAmount} placeholder="Enter weekly paydown amount" />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="paydownOriginalBalance">Total amount driver is paying off</Label>
                                    <Input type={"number"} name="paydownOriginalBalance" id="paydownOriginalBalance" onChange={this.updateInput} defaultValue={this.state.input.paydownOriginalBalance} placeholder="Enter full paydown balance" />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="reason">Select paydown reason</Label>
                                    <Input type="select" name="reason" id="reason" onChange={this.updateInput} placeholder="Select Reason">
                                        <option value=""></option>
                                        {this.props.paymentPlanReasons && this.props.paymentPlanReasons.states.map((reason, i) =>
                                            <option key={i} value={reason.description}>{reason.description}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="paydownStartDate">When is the paydown starting</Label>
                                    <Input type={"date"} name="paydownStartDate" id="paydownStartDate" onChange={this.updateInput} defaultValue={this.state.input.paydownStartDate} placeholder="Start date" />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="paydownNotes">Paydown notes</Label>
                                    <Input type={"text"} name="paydownNotes" id="paydownNotes" onChange={this.updateInput} defaultValue={this.state.input.paydownNotes} placeholder="Notes" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.createPaymentPlan} disabled={this.state.loading || this.state.input.reason === "" || this.state.input.paydownAmount === 0 || this.state.input.paydownOriginalBalance === 0 || (this.state.input.paydownOriginalBalance < this.state.input.paydownAmount)}>{this.state.loading ? "Loading..." : "Create Payment Plan"}</Button>{' '}
                        <Button color="secondary" onClick={this.props.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { paymentPlanReasons } }) { return { paymentPlanReasons } } }),
)(NewPaydown)