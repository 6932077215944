import React, { Component } from "react"
import { compose, graphql } from 'react-apollo'
import gql from "graphql-tag"
import { Table, Badge } from "reactstrap"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import DriverUpdateTagsPopover from "../Drivers/Driver/Tags/DriverUpdateTagsPopover"
import "../Material/ListingHeader.css"
import "./Argyle.css"
const AllArgyleDisconnectedUsers = gql`query AllArgyleDisconnectedUsers($first:Int!,$searchTerm: String, $cursor: String,){
    allArgyleDisconnectedUsers(first:$first, argyleConnected:false, searchTerm: $searchTerm, after: $cursor,){
        edges{
            node{
                id
                pk
                name
                notes
                argyleInviteId
                argyleAccountId
                isDisabled
                isRepo
                tags{
                    id
                    name
                    color
                }
                branch{
                    id 
                    name
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
    } 
}`

const tableHeaders = [
    { id: "pk", name: "ID" },
    { id: "driver_info", name: "Driver Info" },
    { id: "driver_branch", name: "Driver Branch" },
    { id: "driver_notes", name: "Driver Notes" },
    { id: "driver_tags", name: "Driver Tags" },
    { id: "argyle_status", name: "Argyle Status" },
]

class ArgyleNeverConnectedUserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectAll: false,
            openModal: "",
            showEdit: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    toggleEdit = (editName) => {
        this.setState({ showEdit: this.state.showEdit === editName ? "" : editName })
    }

    render() {
        return (
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.allArgyleDisconnectedUsers && this.props.allArgyleDisconnectedUsers.edges.length} pageInfo={this.props.allArgyleDisconnectedUsers && this.props.allArgyleDisconnectedUsers.pageInfo} loadMoreEntries={this.props.allArgyleDisconnectedUsers && this.props.allArgyleDisconnectedUsers.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchDriversQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            {tableHeaders.map(header => (
                                <th key={header.id}>{header.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.allArgyleDisconnectedUsers && this.props.allArgyleDisconnectedUsers.edges && this.props.allArgyleDisconnectedUsers.edges.length > 0 ? this.props.allArgyleDisconnectedUsers.edges.map((argyle, i) =>
                            <tr key={i}>
                                <td scope="row">{i + 1}</td>
                                <td><a className="bos-custom-link" href={'/driver/' + argyle.node.id} target="_blank">
                                    {argyle.node.name.trim() ? argyle.node.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                </a></td>
                                <td><Badge color="primary" style={{ fontSize: 12 }}>{argyle.node && argyle.node.branch && argyle.node.branch.name}</Badge></td>
                                <td>{argyle.node && argyle.node.notes ? argyle.node.notes : "--"}</td>
                                <td>
                                    <div className="d-flex">
                                        <span className='pr-3 align-self-center'>
                                            <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleEdit("drivertags" + argyle.node.pk)} id={"drivertags" + argyle.node.pk}></i>
                                            <DriverUpdateTagsPopover isOpen={this.state.showEdit === "drivertags" + argyle.node.pk} handleClose={() => this.toggleEdit("")} driverId={argyle.node.id} target={"drivertags" + argyle.node.pk} driverTags={argyle.node.tags} refetchQuery={this.props.refetchDriversQuery} />
                                        </span>
                                        <span className='pl-3' style={{ maxWidth: "150px", borderLeft: "1px solid #e1e1e1" }}>
                                            {argyle.node.isRepo && <><Badge color="danger">REPO</Badge></>}
                                            {argyle.node.isDisabled && <><Badge color="danger">DISABLED</Badge></>}
                                            {argyle.node.tags && argyle.node.tags.map((tag, i) =>
                                                <><Badge key={i} style={{ background: `#${tag.color ? tag.color : "grey"}` }}>{tag.name}</Badge></>
                                            )}
                                        </span>
                                    </div>
                                </td>
                                <td >
                                    <Badge style={{ color: (!argyle.node.argyleAccountId && argyle.node.argyleInviteId) ? "white" : "#BA8D08", background: (!argyle.node.argyleAccountId && argyle.node.argyleInviteId) ? "green" : "#FEE4AE", fontSize: 12 }}>{(!argyle.node.argyleAccountId && argyle.node.argyleInviteId) ? "INVITED" : "NEVER CONNECTED"}</Badge>
                                </td>
                            </tr>
                        ) : !this.props.loading &&
                        <tr>
                            <td>No Argyle Account Found</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </PrimaryListingContainer >
        )
    }
}

export default compose(
    graphql(AllArgyleDisconnectedUsers, {
        options: ({ searchTerm }) => ({
            variables: {
                searchTerm,
                first: 30,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allArgyleDisconnectedUsers, fetchMore, searchTerm, variables, refetch } }) {
            return {
                loading,
                allArgyleDisconnectedUsers,
                variables,
                refetch,
                refetchDriversQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allArgyleDisconnectedUsers && allArgyleDisconnectedUsers.edges ? allArgyleDisconnectedUsers.edges.length : 30
                    return fetchMore({
                        query: AllArgyleDisconnectedUsers,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allArgyleDisconnectedUsers.edges
                            const pageInfo = fetchMoreResult.allArgyleDisconnectedUsers.pageInfo
                            return {
                                allArgyleDisconnectedUsers: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allArgyleDisconnectedUsers.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllArgyleDisconnectedUsers,
                        variables: {
                            cursor: allArgyleDisconnectedUsers.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allArgyleDisconnectedUsers.edges
                            const pageInfo = fetchMoreResult.allArgyleDisconnectedUsers.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allArgyleDisconnectedUsers: {
                                    edges: [...previousResult.allArgyleDisconnectedUsers.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allArgyleDisconnectedUsers.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(ArgyleNeverConnectedUserList)
