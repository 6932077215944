import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment-timezone'
import { Row, Col, Alert, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, UncontrolledTooltip, Input, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import NewRefundModal from '../Billing/Refunds/NewRefundModal'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import toTitleCase from "../Functions/toTitleCase"
import { HasPermissionsQuery } from "../Functions/querys"

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
    </GoogleMap>
))
const DriverDetailQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        deposit
        balance
        address
        id
        name
    }
}`

const scheduleReturnForDriver = gql`
mutation scheduleReturnForDriver($input: ScheduleReturnInput!){
    scheduleReturnForDriver(input:$input){
    ok
    errors{
        messages
    }
}
} `
const scheduleSwitchForDriver = gql`
mutation scheduleSwitchForDriver($input: ScheduleSwitchInput!){
    scheduleSwitchForDriver(input:$input){
    ok
    errors{
        messages
    }
}
} `
const locateCar = gql`
mutation locateCar($input: TrackerStatusChangeMutationInput!){
    changeTrackerStatus(input: $input){
        ok
        errors{
            field
            messages
        }
        car {
            id
            pk
            trackerStatus
            hasTracker
        }
        location{
            address
            longitude
            latitude
            speed
            date
        }
    }
}`
const changeReturn = gql`
mutation changeReturn($input: ChangeReturnInput!){
    changeReturn(input:$input){
    ok
    errors{
        messages
    }
}
} `
const returnReasons = gql`
query returnReasons{
    globalSettings (settingsName_Icontains:"return_reasons") {
        edges {
          node {
            settingsName
            settingsValue
          }
        }
    }
}`
const AllCarLocationsQuery = gql`
  query AllCarLocations($branchId:ID!){
    allCarLocations(branchId:$branchId, isReturnLocation:true){
        edges{
            node{
                id
                pk
                name
                isDefaultReturnLocation
            }
        }
    }
  }
`
const categories = {
    Return: ["Final Return", "Mechanical Return", "Accident Return", "Repo Return"],
    Switch: ["Personal Switch", "Accident Switch", "Mechanical Switch", "Insurance/TLC Switch", "Split Switch"]
}

class ScheduleReturnForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            returnType: this.props.carReturn && this.props.carReturn.returnType ? this.props.carReturn.returnType.charAt(0).toUpperCase() + this.props.carReturn.returnType.slice(1).toLowerCase() : !this.props.editReturn && !this.props.closeReturn ? "Return" : null,
            categories: this.props.carReturn && this.props.carReturn.returnType && this.props.carReturn.returnType == "SWITCH" ? categories["Switch"] : (!this.props.editReturn && !this.props.closeReturn) || this.props.carReturn.returnType == "RETURN" ? categories["Return"] : null,
            returnCategory: this.props.carReturn && this.props.carReturn.returnCategory ? toTitleCase(this.props.carReturn.returnCategory) : !this.props.editReturn && !this.props.closeReturn ? "Final Return" : null,
            returnScheduled: false,
            returnReason: this.props.carReturn && this.props.carReturn.returnReason ? this.props.carReturn.returnReason.trim() : null,
            returnDate: this.props.carReturn && this.props.carReturn.scheduledDate ? this.props.carReturn.scheduledDate : null,
            willPickInFuture: this.props.carReturn && this.props.carReturn.nextPickupDate ? true : false,
            futurePickupDate: this.props.carReturn && this.props.carReturn.nextPickupDate ? this.props.carReturn.nextPickupDate : "",
            autoDepositRefund: this.props.editReturn && this.props.carReturn && this.props.carReturn.autoDepositRefund ? this.props.carReturn.autoDepositRefund : false,
            actualReturnReason: null,
            carStage: null,
            // refundDriver: null,
            returningLocation: null,
            location: null,
            ignoreUnscheduledWarning: false,
            // showRefundDialog: false,
            loading: false,
            error: null,
            openModal: "",
            allReturnReasons: null,
            returnReasons: null,
        }
    }
    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value

        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        if (e.target.type == "checkbox" || e.target.type == "radio") {
            value = !this.state[name]
            if (name == "willPickInFuture" && !value) this.setState({ futurePickupDate: "" })
        }
        if (name === "returnType") {
            this.setReturnReasons(this.props.globalSettings, value == "Return" ? "Final Return" : "Personal Switch")
            this.setState({
                categories: categories[value],
                returnCategory: value == "Return" ? "Final Return" : "Personal Switch",
                willPickInFuture: false,
                futurePickupDate: ""
            })
        }
        if (name === "returnCategory") {
            this.setReturnReasons(this.props.globalSettings, value)
        }

        this.setState({ [name]: value })
    };
    handleClose = (e) => {
        //Reset state
        let state = {
            loading: false,
            error: null,
            returnType: null,
            categories: null,
            returnCategory: null,
            returnScheduled: false,
            openModal: "",
            returnReason: null,
            actualReturnReason: null,
            // showRefundDialog: false,
            location: null,
            returnDate: null,
            willPickInFuture: false,
            futurePickupDate: null,
            allReturnReasons: null,
            returnReasons: null,
        }
        this.setState({ ...state })
        if (this.props.refetchQuery) {
            this.props.refetchQuery()
        }
        this.props.handleClose()
    };
    // handleRefundDialogClose = () => {
    //     this.setState({ showRefundDialog: false })
    //     this.handleClose()
    // };
    scheduleReturn = () => {
        try {
            this.setState({ loading: true })
            if ((this.props.editReturn && !this.props.closeReturn) || (!this.props.editReturn && !this.props.closeReturn)) {
                if (!this.state.returnDate) {
                    this.setState({ loading: false, returnScheduled: false, error: "Please provide returning date, in order to proceed!" })
                    return
                }
            }
            if (!this.state.returnReason && ["Personal Switch", "Final Return"].includes(this.state.returnCategory)) {
                this.setState({ loading: false, returnScheduled: false, error: ("Please provide reason for " + this.state.returnCategory + ", in order to proceed!") })
                return
            }
            if (!this.state.returningLocation && !this.props.editReturn && !this.props.closeReturn) {
                this.setState({ loading: false, returnScheduled: false, error: "Please provide returning location, in order to proceed!" })
                return
            }
            let input = {
                returnReason: this.state.returnReason,
                returningLocation: this.state.returningLocation,
            }

            if (this.props.editReturn) {
                input["carReturnId"] = this.props.carReturn && this.props.carReturn.id ? this.props.carReturn.id : null
                input["autoDepositRefund"] = this.state.autoDepositRefund
            } else {
                input["rentalId"] = this.props.rental && this.props.rental.id ? this.props.rental.id : null
            }

            if ((this.props.editReturn && moment(this.state.returnDate).format(moment.HTML5_FMT.DATETIME_LOCAL) != moment(this.props.carReturn.scheduledDate).format(moment.HTML5_FMT.DATETIME_LOCAL)))
                input["returnDate"] = moment(this.state.returnDate).format(moment.HTML5_FMT.DATETIME_LOCAL)
            else if (!this.props.editReturn)
                input["returnDate"] = moment(this.state.returnDate).format(moment.HTML5_FMT.DATE)

            if ((this.props.editReturn && this.props.closeReturn) || (!this.props.editReturn && !this.props.closeReturn))
                input["returnCategory"] = this.state.returnCategory

            if (this.props.closeReturn) {
                input["returnStage"] = 4
            }
            if (this.state.returnType == "Return" && this.state.returnDate && moment(this.state.returnDate).diff(moment().tz("America/New_York").format(), 'hours') <= 144)
                input["ignoreUnscheduledWarning"] = this.state.ignoreUnscheduledWarning

            if (this.state.returnType == "Return" && this.state.willPickInFuture) {
                input["futurePickupDate"] = moment(this.state.futurePickupDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)
            }
            this.props.client.mutate({
                mutation: this.props.editReturn ? changeReturn : (this.state.returnType == "Return" ? scheduleReturnForDriver : scheduleSwitchForDriver),
                variables: { input },
            }).then((result) => {
                if (result.data && (
                    (this.props.editReturn && result.data.changeReturn && result.data.changeReturn.ok) ||
                    (!this.props.editReturn && this.state.returnType == "Return" && result.data.scheduleReturnForDriver && result.data.scheduleReturnForDriver.ok) ||
                    (!this.props.editReturn && this.state.returnType == "Switch" && result.data.scheduleSwitchForDriver && result.data.scheduleSwitchForDriver.ok)
                )) {
                    if (this.props.editReturn && this.props.closeReturn && this.state.returnType && this.state.returnType == "Return" && this.state.returnDate && this.props.carReturn.scheduledDate && moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) == moment(this.props.carReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)) {
                        let driverId = this.props.driver && this.props.driver.id ? this.props.driver.id : null
                        if (driverId) {
                            this.props.client.query({
                                query: DriverDetailQuery,
                                variables: { id: driverId }
                            }).then((result) => {
                                this.setState({ loading: false })
                                this.handleClose()
                                // const totalBalance = parseFloat(result.data.driver.deposit - result.data.driver.balance)
                                // if (totalBalance > 0) {
                                //     this.setState({ refundDriver: result.data.driver, showRefundDialog: true })
                                // } else {
                                //      this.handleClose()
                                // }
                            })
                        } else {
                            this.setState({ loading: false })
                            this.handleClose()
                        }
                    } else {
                        this.setState({ loading: false, returnScheduled: true, error: null })
                        this.handleClose()
                    }
                } else {
                    let errorMessage = "An error has occured. Please contact system admin"
                    if (!this.props.editReturn && result.data && this.state.returnType == "Return" && result.data.scheduleReturnForDriver && result.data.scheduleReturnForDriver.errors && result.data.scheduleReturnForDriver.errors[0] && result.data.scheduleReturnForDriver.errors[0].messages[0])
                        errorMessage = result.data.scheduleReturnForDriver.errors[0].messages[0]
                    else if (!this.props.editReturn && result.data && this.state.returnType == "Switch" && result.data.scheduleSwitchForDriver && result.data.scheduleSwitchForDriver.errors && result.data.scheduleSwitchForDriver.errors[0] && result.data.scheduleSwitchForDriver.errors[0].messages[0])
                        errorMessage = result.data.scheduleSwitchForDriver.errors[0].messages[0]
                    else if (this.props.editReturn && result.data.changeReturn && result.data.changeReturn.errors && result.data.changeReturn.errors[0] && result.data.changeReturn.errors[0].messages[0])
                        errorMessage = result.data.changeReturn.errors[0].messages[0]
                    else
                        errorMessage = "An error has occured, Please contact Admin!"
                    this.setState({ loading: false, returnScheduled: false, error: errorMessage })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured" + error
                this.setState({ error: errorMessage, loading: false, returnScheduled: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured " + err
            this.setState({ error: errorMessage, loading: false, returnScheduled: false, })
        }
    };
    getCarReturnState = (state) => {
        if (!this.props.returnStages) {
            return "------"
        }
        let sobj = this.props.returnStages.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "------"
    }
    locateCar = () => {
        if (this.props.car && this.props.car.id) {
            this.setState({ loading: true })
            let input = { carId: this.props.car.id, command: 'Locate' }
            let location = { address: "", longitude: "", latitude: "", speed: "", date: "" }

            this.props.locate(input).then(result => {
                this.setState({ changingStatus: false })
                if (result.data.changeTrackerStatus.location) {
                    location = result.data.changeTrackerStatus.location
                    location = {
                        address: location.address, longitude: Number(location.longitude), latitude: Number(location.latitude), speed: location.speed, date: location.date
                    }
                    this.setState({ location: location, openModal: "Location", loading: false })
                } else {
                    this.setState({ error: "An error occured while retrieving the location", loading: false })
                }
            }).catch(error => {
                this.setState({ error: "An error occured while retrieving the location", loading: false })
            })
        }
    }
    setReturnReasons = (gSettings = this.props.globalSettings, category = this.state.returnCategory) => {
        if (gSettings && gSettings.edges && gSettings.edges.length > 0) {
            let returnReasons = JSON.parse(gSettings.edges[0].node.settingsValue.replace(/'/g, '"'))
            let allReturnReasons = returnReasons.returnReasons ? Object.keys(returnReasons.returnReasons) : []
            allReturnReasons = allReturnReasons.concat(returnReasons.returnReasons ? Object.keys(returnReasons.switchReasons) : [])

            if (category === "Repo Return" && returnReasons.repoReason)
                returnReasons = returnReasons.repoReason
            else if (category === "Final Return" && returnReasons.returnReasons)
                returnReasons = returnReasons.returnReasons
            else if (category === "Personal Switch" && returnReasons.switchReasons)
                returnReasons = returnReasons.switchReasons
            else
                returnReasons = null
            returnReasons = returnReasons ? Object.keys(returnReasons) : null

            this.setState({ returnReasons: returnReasons, allReturnReasons: allReturnReasons, returnReason: this.state.returnReason && returnReasons && returnReasons.length > 0 && returnReasons.map(reason => reason.trim()).includes(this.state.returnReason) ? this.state.returnReason : null })
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.allCarLocations !== prevProps.allCarLocations && (!this.props.carReturn || !this.props.carReturn.location)) {
            this.setState({
                returningLocation: this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 && this.props.allCarLocations.edges.filter(location => location.node.isDefaultReturnLocation)[0]
                    ? this.props.allCarLocations.edges.filter(location => location.node.isDefaultReturnLocation)[0].node.pk
                    : this.props.car && this.props.car.location && this.props.allCarLocations.edges.filter(location => location.node.name.trim() == this.props.car.location.trim()).length > 0 ? this.props.allCarLocations.edges.filter(location => location.node.name.trim() == this.props.car.location.trim())[0].node.pk : null
            })
        } else if (this.props.allCarLocations !== prevProps.allCarLocations && this.props.carReturn && this.props.carReturn.location) {
            this.setState({
                returningLocation: this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 ?
                    this.props.allCarLocations.edges.filter(loc => loc.node.name.trim() == this.props.carReturn.location.trim())[0].node.pk : null
            })
        }

        if (this.props.globalSettings !== prevProps.globalSettings && this.props.globalSettings.edges && this.props.globalSettings.edges.length > 0) {
            this.setReturnReasons(this.props.globalSettings)
        }
    }

    toggleSwitch = (toggleName) => {
        let currentState = this.state[toggleName]
        this.setState({ [toggleName]: !currentState })
    }


    componentDidMount() {
        if (this.props.globalSettings && this.props.globalSettings.edges && this.props.globalSettings.edges.length > 0) {
            this.setReturnReasons(this.props.globalSettings)
        }
    }

    render() {
        return (
            <div>
                <Modal className="uploadDocument" isOpen={this.props.open}>
                    {this.props.rental &&
                        <>
                            <ModalHeader>{this.props.closeReturn ? "Close" : (this.props.editReturn ? "Edit" : "Schedule")} Return</ModalHeader>
                            <ModalBody>
                                {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value={100} /></Col></Row>}
                                {this.state.returnDate && this.props.editReturn && this.props.carReturn && this.props.carReturn.scheduledDate && moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) != moment(this.props.carReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) && <Row><Col xs={12}><Alert color="warning">{this.props.closeReturn && "Note: Updating the return date at this stage will not close the return!"} The return will be rescheduled since you have updated the return date.</Alert></Col></Row>}
                                {this.state.error && !this.state.returnScheduled && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                {this.state.returnScheduled && !this.state.error && <Row><Col xs={12}><Alert color="success">Return scheduled successfully!</Alert></Col></Row>}
                                {this.state.openModal === "Location" && this.state.location && this.state.location.latitude && this.state.location.longitude &&
                                    <Popover style={{ width: "450px" }} placement="right" isOpen={this.state.openModal === "Location"} target="LocationPopup" toggle={() => this.setState({ openModal: "" })}>
                                        <PopoverHeader>Car Current Location</PopoverHeader>
                                        <PopoverBody>
                                            <MyMapComponent
                                                isMarkerShown
                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDg4oSLbYS6boBTyYBWHmpIOUU__8Mfh4I&v=3.exp&libraries=geometry,drawing,places"
                                                loadingElement={<div style={{ height: `100%` }} />}
                                                containerElement={<div style={{ height: `400px` }} />}
                                                mapElement={<div style={{ height: `100%` }} />}
                                                latitude={this.state.location.latitude}
                                                longitude={this.state.location.longitude}
                                            />
                                        </PopoverBody>
                                    </Popover>
                                }
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnType">Is this a Return or Switch?</Label>
                                            {/* <Input type="select" name="returnType" value={this.state.returnType} disabled={this.props.editReturn} onChange={this.updateInput} placeholder="Select Return Reason"> */}
                                            <Input type="select" name="returnType" value={this.state.returnType} defaultValue="default" disabled={!(this.props.editReturn && this.props.closeReturn && this.getCarReturnState(this.props.carReturn.stage).includes("Awaiting Closeout"))} onChange={this.updateInput} placeholder="Select Return Reason">
                                                <option disabled value="default">-- Select Return/Switch --</option>
                                                <option value="Return">Return</option>
                                                <option value="Switch">Switch</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnCategory">Select {this.state.returnType ? this.state.returnType : "Return"} Category</Label>
                                            <Input type="select" name="returnCategory" value={this.state.returnCategory} disabled={!(this.props.editReturn && this.props.closeReturn && this.getCarReturnState(this.props.carReturn && this.props.carReturn.stage).includes("Awaiting Closeout") && (this.state.categories && this.state.categories.length > 0))} onChange={this.updateInput} placeholder="Select Return Category">
                                                <option disabled value="default">-- Select {this.state.returnType ? this.state.returnType : "Return"} Category --</option>
                                                {this.state.categories && this.state.categories.length > 0 && this.state.categories.map(type => <option value={type}>{type}</option>)}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnDate" className="d-block">When is the driver returning?
                                                {this.props.editReturn && <><a onClick={this.locateCar} id="returnDatePopup" aria-hidden="true" className="float-right"><i className="fa fa-info-circle text-warning" aria-hidden="true"></i></a> <UncontrolledTooltip placement="top" target="returnDatePopup">Note: The return will be rescheduled if you update the return date!</UncontrolledTooltip></>}
                                            </Label>
                                            <Input type={this.props.editReturn ? "datetime-local" : "date"} disabled={this.props.carReturn && ["A_4", "A_0", "A_3"].includes(this.props.carReturn.stage)} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)} defaultValue={moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)} name="returnDate" onChange={this.updateInput} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returningLocation" className="d-block">Car Returning Location?
                                                {this.props.editReturn && <><a onClick={this.locateCar} id="LocationPopup" aria-hidden="true" className="float-right"><i className="fa fa-map-marker text-danger" aria-hidden="true"></i></a> <UncontrolledTooltip placement="top" target="LocationPopup">Click to locate the car</UncontrolledTooltip></>}
                                            </Label>
                                            <Input type="select" name="returningLocation" disabled={!this.props.allCarLocations || (this.props.carReturn && ["A_4", "A_0"].includes(this.props.carReturn.stage))} value={this.state.returningLocation || "null"} id="carLocation" onChange={this.updateInput} placeholder={"Select Car Location"}>
                                                {this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 && this.props.allCarLocations.edges.map((location, i) =>
                                                    <option key={i} value={location.node.pk}>{location.node.name.trim()} </option>
                                                )}
                                                <option key={0} value={"null"} disabled>Select Return Location</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="returnReason">Why is the driver {this.state.returnType ? this.state.returnType.toLocaleLowerCase() : "return"}ing?</Label>
                                            <Input type="select" name="returnReason" disabled={!this.state.returnReasons || (this.props.carReturn && ["A_4"].includes(this.props.carReturn.stage))} value={this.state.returnReason} defaultValue="default" id="returnReason" onChange={this.updateInput} placeholder={"Select " + (this.state.returnType ? this.state.returnType : "Return") + " Reason"}>
                                                <option key={-1} disabled value="default">-- Select a reason for {this.state.returnCategory} --</option>
                                                {this.state.returnReasons && this.state.returnReasons.map((reason, i) =>
                                                    <option key={i} value={reason.trim()}>{reason.trim()}</option>
                                                )}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup>
                                            <legend className="col-form-label">Will driver pickup in future?</legend>
                                            <Row>
                                                <Col>
                                                    <FormGroup check>
                                                        <Label check for="willPickInFuture">
                                                            <Input type="radio" name="willPickInFuture" disabled={!this.state.returnType || this.state.returnType == "Switch"} value={false} checked={this.state.willPickInFuture != true} onChange={this.updateInput} />{' '}NO
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup check>
                                                        <Label check for="willPickInFuture">
                                                            <Input type="radio" name="willPickInFuture" disabled={!this.state.returnType || this.state.returnType == "Switch"} value={true} checked={this.state.willPickInFuture === true} onChange={this.updateInput} />{' '}YES
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>

                                    <Col xs={6}>
                                        <FormGroup>
                                            <Label for="futurePickupDate">Future pickup date?</Label>
                                            <Input min={this.state.returnDate ? moment(this.state.returnDate).add(1, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE) : moment().add(1, 'days').tz("America/New_York").format(moment.HTML5_FMT.DATE)} readOnly={!this.state.willPickInFuture || (!this.state.returnType || this.state.returnType == "Switch")} value={moment(this.state.futurePickupDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)} type="date" name="futurePickupDate" onChange={this.updateInput} />
                                        </FormGroup>
                                    </Col>
                                    {this.state.returnType && this.state.returnType.toLocaleLowerCase() !== "switch" && (!this.props.editReturn || moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) != moment(this.props.carReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)) && this.state.returnDate && moment(this.state.returnDate).diff(moment().tz("America/New_York").format(), 'hours') <= 144 &&//144 = 6(days) *24(hours)
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Label for="ignoreUnscheduledWarning" className="text-danger">Return might be unscheduled. Do you want to ignore this?</Label>
                                                <Input type="select" data-type="boolean" name="ignoreUnscheduledWarning" id="ignoreUnscheduledWarning" onChange={this.updateInput} placeholder="Ignore unscheduled warning?">
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_change_auto_deposit_refund") && <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="autoDepositRefund">Auto Deposit Refund</Label>
                                            <Input
                                                type="checkbox"
                                                id="autoDepositRefund"
                                                checked={this.state.autoDepositRefund}
                                                onChange={() => this.toggleSwitch("autoDepositRefund")}
                                                disabled={this.props.carReturn && this.props.carReturn.stage.includes("A_4")}
                                                style={{ transform: 'scale(1.5)', marginLeft: "10px" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>}
                            </ModalBody>
                        </>}
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row><Col xs={12}>
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                {(!this.props.editReturn || (this.props.carReturn && ["A_1", "A_2", "A_3"].includes(this.props.carReturn.stage))) &&
                                    <Button color="secondary" onClick={this.scheduleReturn} disabled={this.state.loading || (this.state.willPickInFuture && !this.state.futurePickupDate)}>
                                        {this.props.closeReturn && this.state.returnDate && this.props.editReturn && this.props.carReturn && this.props.carReturn.scheduledDate && moment(this.state.returnDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) != moment(this.props.carReturn.scheduledDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) ? "Update" : this.props.closeReturn ? "Close" : this.props.editReturn ? "Update" : "Schedule"} Return
                                    </Button>
                                }
                            </Col></Row>
                        }
                    </ModalFooter>
                </Modal>
                {/* Removed On Amit's Request
                {this.state.refundDriver && 
                    <NewRefundModal driverId={this.state.refundDriver.id} handleClose={this.handleRefundDialogClose} open={this.state.showRefundDialog} />
                } */}
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(returnReasons, {
        props({ data: { globalSettings } }) { return { globalSettings } }
    }),
    graphql(locateCar, {
        props: ({ mutate }) => ({
            locate: (input) => mutate({ variables: { input } }),
        })
    }),
    graphql(AllCarLocationsQuery, {
        options: ({ rental }) => ({
            variables: {
                branchId: rental.driver && rental.driver.branch && rental.driver.branch.id ? rental.driver.branch.id : rental.car.branch.id,
            },
            // fetchPolicy: 'cache-first'
        }),
        props({ data: { loading, allCarLocations } }) {
            return { loading, allCarLocations }
        },
    }),
    graphql(HasPermissionsQuery, {
        options: ({ }) => ({ variables: { userPermissions: ["custom_can_change_auto_deposit_refund"] }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { hasPermissions, loading, variables } }) {
            return {
                hasPermissions, loading, variables,
            }
        }
    }),
)(ScheduleReturnForm)
