import gql from 'graphql-tag'
import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Badge, Table, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import DriverUpdateTagsPopover from '../Driver/Tags/DriverUpdateTagsPopover'
import DriverNotes from '../../ApplicationsNew/DriverNotes'
import DocumentModal from '../../Material/DocumentModal/DocumentModal'
import UpdateDriverInfo from '../../ApplicationsNew/UpdateDriverInfo'
import ReservationModalNew from "../../Reservations/Modals/ReservationModalNew"
import Languages from "../../Material/Language.json"
import MassMessageModal from "../../Material/MassMessageModal"
import RepAssignment from "../../Material/RepAssignment"
import PrimaryListingContainer from "../../Material/PrimaryListingContainer"
import './DriversListContainer.css'
import { ReactComponent as InOffice } from "../../assets/images/icons/in-office.svg"

const DriversQuery = gql`query DriversQuery($cursor: String, $first:Int!, $searchTerm: String, $carStatus: String, $assignedTo: String, $orderBy: [String], $tags: [String], $stages:[Int], $pk: Int, $email: String, $phone: String, $tlcLicense: String, $dmvLicense: String,  $minBalance: Float, $maxBalance: Float, $chargeDayofweek: String, $hideFromCollections: Boolean, $hasBalanceWriteOff: Boolean, $hasPaymentPlan: Boolean, $needsCollection: Boolean, $autoCharge: Boolean, $loadReservationSet: Boolean!, $inOffice: Boolean, $loadPaymentPlanSet: Boolean!, $promoNames: [String], $isBlocked: Boolean) {
    drivers: allDrivers(carStatus: $carStatus, first: $first, orderBy: $orderBy, tags: $tags, after: $cursor, searchTerm: $searchTerm, assignedTo: $assignedTo, stages: $stages, pk: $pk, email: $email, phone: $phone, tlcLicense: $tlcLicense, dmvLicense: $dmvLicense, balance_Gte:$minBalance, balance_Lte:$maxBalance, chargeDayofweek: $chargeDayofweek, hideFromCollections: $hideFromCollections, hasBalanceWriteOff: $hasBalanceWriteOff, hasPaymentPlan: $hasPaymentPlan, needsCollection: $needsCollection, autoCharge: $autoCharge, inOffice: $inOffice, promoNames: $promoNames, isBlocked: $isBlocked){
      edges{    
        cursor
        node{
          id
          pk
          name
          phone
          email
          preferredLanguage
          isTlc
          tlcLicense
          dmvLicense
          balance
          currentCharge
          autoCharge
          gender
          isBlocked
          paymentplanSet(status:"1",last:1) @include(if: $loadPaymentPlanSet){
            edges {
              node {
                id
              }
            }
          }
          paymentMethod{
            id
            name
          }
          chargeDayofweek
          weeklyBillingNotes
          inOffice
          assignedReps{
            edges{
                node{
                    id
                    username
                }
            }
          }
          isDisabled
          isRepo
          branch{
              id
              name
          }
          mobileappdriver{
            id
          }
          tags{
              id
              name
              color
          }
          reservationDriver(status:"Open") @include(if: $loadReservationSet){
            edges {
              node {
                id
              }
            }
          }
        }
      }
      pageInfo{
          endCursor
          hasNextPage
          length
      }
    } 
}`

const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            messages
            }
        }
    } `

const chargeDayofWeek = {
    0: "Monday", 1: "Tuesday", 2: "Wednesday", 3: "Thursday", 4: "Friday", 5: "Saturday", 6: "Sunday"
}

const genders = [{ "value": "M", "label": "Male" }, { "value": "F", "label": "Female" }, { "value": "O", "label": "Other" }]

// const tableHeaders = [
//     { id: "id", name: "Driver Info", sortable: true, views: ["collections", "drivers"] },
//     { id: "branch__name", name: "Branch", sortable: false, views: ["drivers", "collections"] },
//     { id: "auto_charge", name: "AutoCharge", sortable: false, views: ["collections"] },
//     { id: "balance", name: "Balance", sortable: true, views: ["collections"] },
//     { id: "weekly_billing_notes", name: "Billing Notes", sortable: false, views: ["collections", "drivers"] },
//     { id: "tags", name: "Tags", sortable: false, views: ["collections", "drivers"] },
//     { id: "assigned_to__name", name: "Assigned Rep", sortable: false, views: ["collections", "drivers"] },
// ]

class DriversList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            selectAll: false,
            showEdit: "",
            selectedDriverPreview: null
        }
    }

    updateSelected = (value) => {
        let selectedDrivers = this.props.selectedDrivers
        if (selectedDrivers.includes(value)) {
            selectedDrivers = selectedDrivers.filter(item => item !== value)
        } else {
            selectedDrivers.push(value)
        }
        this.props.setSelectedDrivers(selectedDrivers)
    };

    toggleSelectAllClick = () => {
        if (this.props.drivers && !this.state.selectAll) {
            this.props.setSelectedDrivers(this.props.drivers.edges.map(n => n.node.id))
            this.setState({ selectAll: true })
        } else {
            this.props.setSelectedDrivers([])
            this.setState({ selectAll: false })
        }
    };

    renderLangCode(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }

    toggleEdit = (editName) => {
        this.setState({ showEdit: this.state.showEdit === editName ? "" : editName })
    }

    updateDriver = (id, newvals) => {
        let input = {
            id: id,
            ...newvals
        }
        this.props.client.mutate({
            mutation: UpdateDriver,
            variables: { input }
        }).then(result => { this.props.refetchDriversQuery() })
    }

    toggleAutoCharge = (driver) => {
        if (driver) {
            this.updateDriver(driver.id, { autoCharge: !driver.autoCharge })
        }
    }

    showDriverDetailPreview = (show, selectedDriver) => {
        this.setState({ selectedDriver: selectedDriver })
        this.setState({ showDriverDetailPreview: show })
    };

    updateOrder = (value) => {
        this.props.updateOrder(value)
    };

    clearSelectedDrivers = () => {
        this.setState({ selectedDrivers: [] })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refetchDrivers !== this.props.refetchDrivers && this.props.refetchDrivers) {
            this.props.refetchDriversQuery()
            this.props.setRefetchQuery()
        }
    }
    render() {
        const { columnConfig } = this.props
        return (
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.drivers && this.props.drivers.edges.length} pageInfo={this.props.drivers && this.props.drivers.pageInfo} loadMoreEntries={this.props.drivers && this.props.drivers.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchDriversQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {columnConfig && columnConfig.filter(col => col.visible).map((column, index) => (
                                column.sortable ?
                                    <th key={column.id} onClick={() => this.props.updateOrder(column.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(column.id)) ? <i className={"fa " + (this.props.orderBy.includes(column.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{column.name}</th> :
                                    <th key={column.id}>{column.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.drivers && this.props.drivers.edges && this.props.drivers.edges.length > 0 ? this.props.drivers.edges.map((driver, i) => <tr key={driver.node.id} className={driver.node && driver.node.inOffice && "driver-in-office-row"}>
                            <td scope="row" className="listing-checkbox-wrapper">
                                {!this.props.selectedDrivers.includes(driver.node.id) && <span className="listing-counter">{i + 1}</span>}
                                <span className="listing-checkbox" onClick={() => this.updateSelected(driver.node.id)}>
                                    {this.props.selectedDrivers.includes(driver.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                </span>
                            </td>
                            {columnConfig && columnConfig.filter(column => column.visible).map(column =>
                                <>
                                    {column.id === "id" &&
                                        <td>
                                            {this.state.openModal === "NewReservationModal" + i && <ReservationModalNew handleClose={() => this.setState({ openModal: null })} open={this.state.openModal === "NewReservationModal" + i} driverId={driver.node.id} refetchQuery={this.props.refetchDriversQuery} title={"New Reservation for Driver " + driver.node.name} />}
                                            {(driver.node.reservationDriver && driver.node.reservationDriver.edges[0] && driver.node.reservationDriver.edges[0].node && driver.node.reservationDriver.edges[0].node.id && this.state.openModal === "UpdateReservationModal" + i) &&
                                                <ReservationModalNew id={driver.node.reservationDriver.edges[0].node.id} title="Update Reservation" open={this.state.openModal === "UpdateReservationModal" + i} isUpdate={true} handleClose={() => this.toggleModal("")} />
                                            }
                                            {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[driver.node.id]} />}
                                            {this.state.openModal === "driverDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "driverDocuments" + i} objectType="driver" id={driver.node.id} currentUser={this.props.currentUser} />}
                                            <div className="driver-detail-block">
                                                {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"driverInfo" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                                    <label>{driver.node.name}</label>
                                                    <span className="bos-custom-tooltip-header">
                                                        <i className="fa fa-at" aria-hidden="true"></i>&nbsp;&nbsp;{driver.node.email || "--"}<br />
                                                        <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{driver.node.phone || "--"}<br />
                                                    </span>
                                                    <p className="bos-custom-tooltip-body">
                                                        <b>Gender:</b> {driver.node.gender ? genders.find(gender => driver.node.gender === gender.value).label : ""}<br />
                                                        <b>Payment Method:</b> {driver.node.paymentMethod && driver.node.paymentMethod.name ? driver.node.paymentMethod.name : ""}<br />
                                                        {this.props.listingType === "drivers" && driver.node.chargeDayofweek !== null ? <><b>Billing Day:</b> {chargeDayofWeek[driver.node.chargeDayofweek]}<br /></> : ""}
                                                        <b>Driver License:</b> {driver.node.dmvLicense || "--"} <br />
                                                        {driver.node.isTlc && driver.node.tlcLicense && <><b>TLC License:</b> {driver.node.tlcLicense}<br /></>}
                                                        <b>Preferred Language:</b> {driver.node.preferredLanguage ? this.renderLangCode(driver.node.preferredLanguage) : "--"}<br />
                                                        <b>Current Charge:</b> ${driver.node.currentCharge}<br />
                                                    </p>
                                                    <span className="bos-custom-tooltip-footer">
                                                        {<><Button onClick={() => this.toggleModal("MassMessageModal" + i)}><i className="fa fa-comment-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                        {<><Button onClick={() => this.toggleModal("driverDocuments" + i)}><i className="fa fa-folder-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>}
                                                        {this.props.listingType === "drivers" && <>
                                                            <Button id={"reserve-car-" + i} onClick={() => this.setState({ openModal: (driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? "UpdateReservationModal" : "NewReservationModal") + i })}>{driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? <i className="fa fa-calendar-check-o text-success" aria-hidden="true"></i> : <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>}</Button>
                                                            {/* <UncontrolledTooltip placement="bottom" target={"reserve-car-" + i}>{driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? "Edit Reservation Info" : "Create Reservation"}</UncontrolledTooltip> */}
                                                            &nbsp;&nbsp;</>}
                                                    </span>
                                                </UncontrolledTooltip>}
                                                <div className="d-flex" id={"driverInfo" + i}>
                                                    <span>
                                                        <small>{this.props.listingType !== "collections" && <b className={driver.node.balance && driver.node.balance > 0 ? "text-danger" : "text-success"}>${driver.node.balance}</b>}{this.props.listingType == "collections" && driver.node.chargeDayofweek !== null ? chargeDayofWeek[driver.node.chargeDayofweek] : ""}</small>
                                                        <br />
                                                        <a className="bos-custom-link" href={'/driver/' + driver.node.id} target="_blank">
                                                            {driver.node.name.trim() ? driver.node.name : <span style={{ color: "#ff0000" }}>No Name Found</span>}
                                                        </a>
                                                        &nbsp;&nbsp;({driver.node.dmvLicense ? driver.node.dmvLicense : "--"})
                                                    </span>
                                                </div>
                                                <ul className="driver-detail-actions pt-2">
                                                    {driver.node.isBlocked && <>
                                                        <i id={"isBlocked" + i} class="fa fa-ban fa-lg text-danger" aria-hidden="true" ></i>&nbsp;&nbsp;
                                                        <UncontrolledTooltip target={"isBlocked" + i}>Blocked</UncontrolledTooltip>
                                                    </>
                                                    }
                                                    {driver.node && driver.node.inOffice &&
                                                        <li>
                                                            <InOffice id={"inOffice" + String(driver.node.pk)} width={25} height={25} fill="#198754" />
                                                            <UncontrolledTooltip target={"inOffice" + String(driver.node.pk)}>Driver in Office</UncontrolledTooltip>
                                                        </li>
                                                    }
                                                    {(!driver.node.mobileappdriver || !driver.node.mobileappdriver.id) &&
                                                        <li>
                                                            <i className={"fa fa-mobile text-danger fa-lg"} id={"driverNotUsingMobile" + i}></i>
                                                            <UncontrolledTooltip placement="bottom" target={"driverNotUsingMobile" + i}>Not Using Mobile App</UncontrolledTooltip>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </td>
                                    }
                                    {column.id === "branch__name" &&
                                        <td>
                                            <Badge className='bos-badge-blue'>{driver.node.branch && driver.node.branch.name ? driver.node.branch.name : "No Branch Selected"}</Badge>
                                        </td>
                                    }
                                    {column.id === "auto_charge" && this.props.listingType === "collections" &&
                                        <td onClick={() => this.toggleAutoCharge(driver.node)} style={{ color: "#007bff", cursor: "pointer" }}>
                                            {driver.node.autoCharge ? <i className="fa fa-toggle-on" aria-hidden="true"></i> : <i className="fa fa-toggle-off" aria-hidden="true"></i>}
                                        </td>
                                    }
                                    {column.id === "balance" && this.props.listingType === "collections" &&
                                        <td className="capitalize">
                                            {driver.node.paymentplanSet && driver.node.paymentplanSet.edges && driver.node.paymentplanSet.edges.length > 0 ? <span className={driver.node.currentCharge && driver.node.currentCharge > 0 ? "text-danger" : "text-success"}>
                                                <s>${driver.node.balance}</s>&nbsp;&nbsp;${driver.node.currentCharge}
                                            </span> :
                                                <span className={driver.node.balance && driver.node.balance > 0 ? "text-danger" : "text-success"}>
                                                    ${driver.node.balance}
                                                </span>}
                                        </td>
                                    }
                                    {column.id === "weekly_billing_notes" &&
                                        <td className="shortText" style={{ maxWidth: "15rem" }}>
                                            <small id={"weeklyBillingNotes" + driver.node.pk}>{driver.node.weeklyBillingNotes ? driver.node.weeklyBillingNotes : "--"}</small>
                                            <UncontrolledTooltip placement="right" target={"weeklyBillingNotes" + driver.node.pk}>{driver.node.weeklyBillingNotes} </UncontrolledTooltip>
                                            <br />
                                            {this.state.openModal === i + "notesHistory" && <DriverNotes driver={driver.node} noteType="BILLING" title="Billing Notes History" target={"notesHistory" + i} open={this.state.openModal === i + "notesHistory"} handleClose={() => this.toggleModal(i + "notesHistory")} placement={"left"} />}
                                            {this.state.openModal === "notesEdit" + i && <UpdateDriverInfo driverId={driver.node.id} title="Edit Driver Billing Notes" name="weeklyBillingNotes" value={driver.node.weeklyBillingNotes}
                                                target={"notesEdit" + i} id="notes" type="textarea" open={this.state.openModal === "notesEdit" + i}
                                                handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriversQuery}
                                            />}
                                            <i id={"notesEdit" + i} onClick={() => this.toggleModal("notesEdit" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                            <i id={"notesHistory" + i} onClick={() => this.toggleModal(i + "notesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                        </td>
                                    }
                                    {column.id === "tags" &&
                                        <td>
                                            <div className="d-flex">
                                                <span className='pr-3 align-self-center'>
                                                    <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleEdit("drivertags" + driver.node.pk)} id={"drivertags" + driver.node.pk}></i>
                                                    <DriverUpdateTagsPopover isOpen={this.state.showEdit === "drivertags" + driver.node.pk} handleClose={() => this.toggleEdit("")} driverId={driver.node.id} target={"drivertags" + driver.node.pk} driverTags={driver.node.tags} refetchQuery={this.props.refetchDriversQuery} />
                                                </span>
                                                <span className='pl-3' style={{ maxWidth: "150px", borderLeft: "1px solid #e1e1e1" }}>
                                                    {driver.node.isRepo && <><Badge color="danger">REPO</Badge></>}
                                                    {driver.node.isDisabled && <><Badge color="danger">DISABLED</Badge></>}
                                                    {driver.node.tags && driver.node.tags.map((tag, i) =>
                                                        <><Badge key={i} style={{ background: `#${tag.color ? tag.color : "grey"}` }}>{tag.name}</Badge></>
                                                    )}
                                                </span>
                                            </div>
                                        </td>
                                    }
                                    {column.id === "assigned_to__name" &&
                                        <td>
                                            <div className="d-flex">
                                                <span className='pr-3 align-self-center'>
                                                    <i className={"fa fa-pencil fa-lg"} onClick={() => this.toggleEdit("rep" + driver.node.pk)} id={"rep" + driver.node.pk}></i>
                                                    {this.state.showEdit === "rep" + driver.node.pk && <Popover isOpen={this.state.showEdit === "rep" + driver.node.pk} placement="bottom" target={"rep" + driver.node.pk}>
                                                        <PopoverHeader onClick={() => this.toggleEdit("")}>Assign Rep <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                                                        <PopoverBody style={{ minWidth: "200px" }}>
                                                            <RepAssignment handleClose={() => this.toggleEdit("rep" + driver.node.pk)} driver={driver.node} defaultValue={driver.node.assignedReps.edges} refetchDrivers={this.props.refetchDriversQuery} groups={this.props.listingType === "collections" ? ["Collectors"] : []} />
                                                        </PopoverBody>
                                                    </Popover>}
                                                </span>
                                                <span className="pl-3" style={{ borderLeft: "1px solid #e1e1e1" }}>
                                                    {driver.node.assignedReps && driver.node.assignedReps.edges && driver.node.assignedReps.edges.length > 0 ? driver.node.assignedReps.edges.map(rep =>
                                                        <span>{rep.node.username}</span>) : "--"
                                                    }
                                                </span>
                                            </div>
                                        </td>
                                    }
                                </>
                            )}
                            {/* <td>
                                <ul className="driver-detail-actions pl-0">
                                    <li>
                                        {this.state.openModal === "MassMessageModal" + i && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal" + i)} open={this.state.openModal === "MassMessageModal" + i} drivers={[driver.node.id]} />}
                                        <a id={"send-message-" + i} onClick={() => this.setState({ openModal: "MassMessageModal" + i })}><i className="fa fa-lg fa-envelope-o" style={{ color: "#303E67" }}></i></a>
                                        <UncontrolledTooltip placement="bottom" target={"send-message-" + i}>Send Message</UncontrolledTooltip>
                                    </li>
                                    {this.props.listingType === "drivers" && <li>
                                        {this.state.openModal === "NewReservationModal" + i && <ReservationModalNew handleClose={() => this.setState({ openModal: null })} open={this.state.openModal === "NewReservationModal" + i} driverId={driver.node.id} refetchQuery={this.props.refetchDriversQuery} title={"New Reservation for Driver " + driver.node.name} />}
                                        {this.state.openModal === "UpdateReservationModal" + i &&
                                            <ReservationModalNew id={driver.node.reservationDriver.edges[0].node.id} title="Update Reservation" open={this.state.openModal === "UpdateReservationModal" + i} isUpdate={true} handleClose={() => this.toggleModal("")} />
                                        }
                                        <a id={"reserve-car-" + i} onClick={() => this.setState({ openModal: (driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? "UpdateReservationModal" : "NewReservationModal") + i })}><ReserveCar width={30} fill={driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? "#28a745" : "#303E67"} /></a>
                                        <UncontrolledTooltip placement="bottom" target={"reserve-car-" + i}>{driver.node.reservationDriver && driver.node.reservationDriver.edges && driver.node.reservationDriver.edges.length > 0 ? "Edit Reservation Info" : "Create Reservation"}</UncontrolledTooltip>
                                    </li>}
                                </ul>
                            </td> */}
                        </tr>) :
                            this.props.loading ? <tr><td colSpan={7}>Loading Drivers List...</td></tr>
                                : <tr>
                                    <th scope="row">&nbsp;</th>
                                    <td colSpan={7}>No Driver Found</td>
                                </tr>}
                    </tbody>
                </Table>
            </PrimaryListingContainer>
        )
    }
}

export default compose(
    withApollo,
    graphql(DriversQuery, {
        options: ({ searchTerm, carStatus, orderBy, assignedTo, tags, stages, pk, email, phone, tlcLicense, dmvLicense, minBalance, maxBalance, chargeDayofweek, hasBalanceWriteOff, hasPaymentPlan, hideFromCollections, needsCollection, autoCharge, listingType, inOffice, promoNames, isBlocked }) => ({
            variables: {
                searchTerm,
                carStatus,
                orderBy,
                assignedTo,
                tags,
                stages,
                pk,
                email,
                phone,
                tlcLicense,
                dmvLicense,
                minBalance,
                maxBalance,
                chargeDayofweek,
                hasBalanceWriteOff,
                hasPaymentPlan,
                hideFromCollections,
                needsCollection,
                autoCharge,
                inOffice,
                isBlocked,
                promoNames,
                first: 30,
                loadPaymentPlanSet: listingType && listingType === "collections" ? true : false,
                loadReservationSet: listingType && listingType === "drivers" ? true : false
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, drivers, fetchMore, searchTerm, variables, refetch } }) {
            return {
                loading,
                drivers,
                variables,
                refetch,
                refetchDriversQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = drivers && drivers.edges ? drivers.edges.length : 30
                    return fetchMore({
                        query: DriversQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.drivers.edges
                            const pageInfo = fetchMoreResult.drivers.pageInfo
                            return {
                                drivers: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.drivers.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: DriversQuery,
                        variables: {
                            cursor: drivers.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.drivers.edges
                            const pageInfo = fetchMoreResult.drivers.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                drivers: {
                                    edges: [...previousResult.drivers.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.drivers.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(DriversList)
