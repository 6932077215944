import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import './CarDetail.css'
import { Badge, Button, Col, Row, Label, Input, UncontrolledTooltip, FormGroup, Nav, NavItem, NavLink } from 'reactstrap'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import toTitleCase from '../../Functions/toTitleCase'
import { CarDetailQuery, AllCarModelGroupTypes, AllCarModelsQuery, AllBranchesQuery } from './Queries'
import { UpdateCarMutation } from "./Mutations"
import CarNotes from '../../Material/CarNotes'
import UpdateCarInfo from '../../Material/UpdateCarInfo'
import InstallEquipment from './EquipmentTab/InstallEquipment'
import Loader from "../../Material/Loader"
import renderEnumToInt from '../../Functions/renderEnumToInt'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import CarDealModal from "../../CarDeals/CarDealModal"
import CopyToClipboard from "../../Material/CopyToClipboard"
const enableDisableHistoryTable = [
    { id: "type", name: "Type", sortable: false },
    { id: "dateAdded", name: "Date Added", sortable: false },
    { id: "status", name: "Status", sortable: false },
    { id: "createdBy", name: "Created By", sortable: true },
]
class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            showEdit: "",
            input: {},
            openModal: "",
            activeReservation: this.props.car &&
                this.props.car.carReservation &&
                this.props.car.carReservation.edges &&
                this.props.car.carReservation.edges.length > 0 ?
                this.props.car.carReservation.edges.find(reservation => reservation.node.status === "Open") : null,
            dealType: "Buying Deal",
            buyingDeal: this.props.car && this.props.car.cardealSet && this.props.car.cardealSet.edges && this.props.car.cardealSet.edges.length > 0 ? this.props.car.cardealSet.edges.find(carDeal => carDeal.node.dealType.toLowerCase() === "buying") : null,
            sellingDeal: this.props.car && this.props.car.cardealSet && this.props.car.cardealSet.edges && this.props.car.cardealSet.edges.length > 0 ? this.props.car.cardealSet.edges.find(carDeal => carDeal.node.dealType.toLowerCase() === "selling") : null,
            editField: "",
            carModelGroups: [],
            carStages: null,
            trackerId: null,
            command: null,
        }
    }
    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        try {
            metadata = JSON.parse(metadata.replace(/'/g, '"'))
        } catch {
            metadata = null
        }
        return metadata && metadata[name] ? metadata[name] : null
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.car !== this.props.car || prevProps.allCarModels !== this.props.allCarModels || prevProps.carStages !== this.props.carStages) {
            let activeReservation = this.props.car && this.props.car.carReservation && this.props.car.carReservation.edges.length > 0 ? this.props.car.carReservation.edges.find(reservation => reservation.node.status === "Open") : null
            let buyingDeal = this.props.car && this.props.car.cardealSet && this.props.car.cardealSet.edges && this.props.car.cardealSet.edges.length > 0 && this.props.car.cardealSet.edges.find(carDeal => carDeal.node.dealType.toLowerCase() === "buying")
            let sellingDeal = this.props.car && this.props.car.cardealSet && this.props.car.cardealSet.edges && this.props.car.cardealSet.edges.length > 0 && this.props.car.cardealSet.edges.find(carDeal => carDeal.node.dealType.toLowerCase() === "selling")
            if (this.props && this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.length > 0) {
                let groups = this.props.allCarModels.edges.filter(model => this.props.car && this.props.car.carModel && model.node.name == this.props.car.carModel.name && model.node.series == this.props.car.carModel.series && model.node.make == this.props.car.carModel.make).map(model => model.node.groupType)
                if (groups) groups = groups.filter(group => group ? group : false)
                this.setState({ carModelGroups: groups })
            }
            this.setState({ activeReservation, buyingDeal: buyingDeal, sellingDeal: sellingDeal })
        }
    }
    editField(type, id) {
        if (id) {
            this.setState({ [type]: id, value: id })
        }
        if (type === this.state.editField) {
            this.setState({ error: null })
        }
        this.setState({ editField: type === this.state.editField ? "" : type })
    }
    updateInput = (e) => {
        let value = e.target.value
        this.setState({ value: value })
    };
    updateCar = (carId, name, value) => {
        let defValue = this.state[name]
        try {
            if (carId && name && value && value !== defValue) {
                this.setState({ loading: true })
                let input = {
                    id: carId,
                    [name]: name === "stage" ? renderEnumToInt(value) : value
                }
                this.props.client.mutate({
                    mutation: UpdateCarMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateCar && result.data.updateCar.ok) {
                        this.setState({ editField: "", loading: false, error: "" })
                        this.props.refetchCarQuery()

                    } else if (result && result.data && result.data.updateCar && result.data.updateCar.errors && result.data.updateCar.errors[0] && result.data.updateCar.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.updateCar.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } else if (value === this.state.value) {
                this.setState({ editField: "", loading: false, error: "" })
            }
        } catch (err) {
            this.setState({ loading: false, error: err })
        }
    }

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    render() {

        return (
            <>
                <div className="tab-container">
                    {this.props.loading ? <Loader /> : <>
                        <h4>CAR DETAILS</h4>
                        <div className="info-container">
                            <Row>
                                <Col>
                                    <Label>MODEL</Label>
                                    <Input disabled value={(this.props.car && this.props.car.carModel) ? toTitleCase(this.props.car.carModel.name) : "--"} />
                                </Col>
                                <Col>
                                    <Label>SERIES</Label>
                                    <Input disabled value={this.props.car && this.props.car.carModel && this.props.car.carModel.series ? this.props.car.carModel.series : "--"} />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={6}>
                                            <Label>GROUP</Label>
                                        </Col>
                                        {this.state.carModelGroups && this.state.carModelGroups.length > 1 &&
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "group" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("group", this.props && this.props.car && this.props.car.carModel ? this.props.car.carModel.groupType.id : "")}></i> :
                                                    <div>
                                                        {this.state.error && <><i id={"error-group"} className="fa fa-exclamation-circle danger" aria-hidden="true" /> <UncontrolledTooltip placement="bottom" target={"error-group"}>{this.state.error}</UncontrolledTooltip></>}&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("group")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateCar(this.props.car && this.props.car.id, "group", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        }
                                    </Row>
                                    <div>
                                        {this.state.editField !== "group" &&
                                            <Input disabled={this.state.editField !== "group"} value={this.props.car && this.props.car.carModel && this.props.car.carModel.groupType ? this.props.car.carModel.groupType.name : "--"} />
                                        }
                                        {this.state.editField === "group" &&
                                            <FormGroup>
                                                <Input type="select" name="group" id="group" value={this.state.carModelGroupType} onChange={this.updateInput} placeholder="Model Group Type">
                                                    <option value="">Model Group Type</option>
                                                    {this.state.carModelGroups && this.state.carModelGroups && this.state.carModelGroups.map((carModelGroupType, i) =>
                                                        <option key={i} value={carModelGroupType.name}>{carModelGroupType.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={6}>
                                            <Label>COLOR</Label>
                                        </Col>
                                        <Col className="text-right" xs={6}>
                                            {this.state.editField !== "color" ?
                                                <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("color", this.props && this.props.car && this.props.car.color ? this.props.car.color : "")}></i> :
                                                <div>
                                                    <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("color")}></i>&nbsp;&nbsp;
                                                    <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateCar(this.props.car && this.props.car.id, "color", this.state.value)}></i>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                    <div>
                                        <>
                                            {this.state.editField !== "group" &&
                                                <Input disabled={this.state.editField !== "color"} defaultValue={this.props.car && this.props.car.color ? this.props.car.color : "--"} onChange={this.updateInput} />
                                            }
                                        </>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>CREATED BY</Label>
                                    <Input disabled value={this.props.car && this.props.car.createdBy ? this.props.car.createdBy.username : "--"} />
                                </Col>
                                <Col>
                                    <Label>CREATED AT</Label>
                                    <Input disabled value={this.props.car && this.props.car.dateAdded ? moment(this.props.car.dateAdded).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={6}>
                                            <Label>Branch</Label>
                                        </Col>
                                        {(this.props.car && !(renderEnumToInt(this.props.car.stage) === 10 && this.props.car.branch && this.props.car.branch.customId === "BROOKLYN_NY")) &&
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "branch" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("branch", this.props && this.props.car && this.props.car.branch ? this.props.car.branch : "")}></i> :
                                                    <div>
                                                        {this.state.error && <><i id={"error" + this.props.car.id} className="fa fa-exclamation-circle danger" aria-hidden="true" /> <UncontrolledTooltip placement="bottom" target={"error" + this.props.car.id}>{this.state.error}</UncontrolledTooltip></>}&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("branch")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateCar(this.props.car && this.props.car.id, "branch", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        }
                                    </Row>
                                    <div>
                                        {this.state.editField !== "branch" &&
                                            <Input disabled={this.state.editField !== "branch"} defaultValue={this.props.car && this.props.car.branch ? this.props.car.branch.name : "--"} />
                                        }
                                        {this.state.editField === "branch" &&
                                            <FormGroup>
                                                <Input type="select" name="branch" id="branch" value={this.state.value} onChange={this.updateInput} placeholder="Branch">
                                                    <option value={this.state.branch.name}>Branch</option>
                                                    {this.props.allBranches && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map((branch, i) =>
                                                        <option key={i} value={branch.node.name}>{branch.node.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={8}>
                                            <Label>CUSTOM NAME</Label>
                                        </Col>
                                        <Col className="text-right">
                                            {this.state.editField !== "customName" ?
                                                <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("customName", this.props && this.props.car && this.props.car.customName ? this.props.car.customName : "")}></i> :
                                                <div>
                                                    <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("customName")}></i>&nbsp;&nbsp;
                                                    <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateCar(this.props.car && this.props.car.id, "customName", this.state.value)}></i>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                    <Input disabled={this.state.editField !== "customName"} defaultValue={this.props.car && this.props.car.customName ? this.props.car.customName : "--"} onChange={this.updateInput} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>LAST DMV INSPECTION</Label>
                                    <Input disabled value={this.props.car && this.props.car.lastDmvInspectionDate ? moment(this.props.car.lastDmvInspectionDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                </Col>
                                <Col>
                                    <Label>LAST OIL CHANGE DATE</Label>
                                    <Input disabled value={this.props.car && this.props.car.lastOilChangeDate ? moment(this.props.car.lastOilChangeDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                </Col>
                                <Col>
                                    <Label>MILES SINCE LAST OIL CHANGE</Label>
                                    <Input disabled value={this.props.car ? this.props.car.mileageSinceLastOilChange : "--"} />
                                </Col>
                                <Col>
                                    <Label>DAYS SINCE DMV</Label>
                                    <Input disabled value={this.props.car ? this.props.car.getDaysTillDmvInspection : "--"} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <Row>
                                        <Col xs={8}>
                                            <Label>PLATE</Label>
                                        </Col>
                                        <Col className="text-right">
                                            <CopyToClipboard textToCopy={this.props.car && this.props.car.plate ? this.props.car.plate.dmvPlate : "--"} displayText={"dmv plate"} />
                                        </Col>
                                    </Row>
                                    <Input disabled value={this.props.car && this.props.car.plate ? this.props.car.plate.dmvPlate : "--"} />
                                </Col>
                                <Col xs={3}>
                                    <Row>
                                        <Col xs={8}>
                                            <Label>FHV</Label>
                                        </Col>
                                        <Col className="text-right">
                                            <CopyToClipboard textToCopy={this.props.car && this.props.car.plate ? this.props.car.plate.fhv : "--"} displayText={"fhv"} />
                                        </Col>
                                    </Row>
                                    <Input disabled value={this.props.car && this.props.car.plate ? this.props.car.plate.fhv : "--"} />
                                </Col>
                                <Col xs={3}>
                                    <Label>FHV EXPIRATION</Label>
                                    <Input disabled value={this.props.car && this.props.car.plate ? moment(this.props.car.plate.fhvExpiration).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div id={"carNotes"}>
                                        <Label>CAR NOTES</Label>&nbsp;&nbsp;
                                        <i onClick={() => this.toggleModal("carNotesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                        <i onClick={() => this.toggleModal("carNotes")} className={"fa fa-pencil-square-o"}></i><br />
                                    </div>
                                    {this.state.openModal === "carNotesHistory" &&
                                        <CarNotes car={this.props.car} noteType="General" title="Car Notes" target={"carNotes"} open={this.state.openModal === "carNotesHistory"} handleClose={() => this.toggleModal("")} placement={"left"} />
                                    }
                                    {this.state.openModal === "carNotes" &&
                                        <UpdateCarInfo
                                            car={this.props.car}
                                            noteType="CAR"
                                            title="Edit Car Notes"
                                            target="carNotes"
                                            type="text"
                                            name="notes"
                                            value={this.props.car.notes}
                                            open={this.state.openModal === "carNotes"}
                                            handleClose={() => this.toggleModal("")}
                                            placement={"left"}
                                            refetchQuery={this.props.refetchCarQuery}
                                        />
                                    }
                                    <Input disabled
                                        value={this.props.car && this.props.car.notes}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <br /><br />
                        <h4>EQUIPMENTS</h4>
                        <div className="info-container">
                            <Row>
                                <Col>
                                    {this.props.car && this.props.car.equipmentSet && this.props.car.equipmentSet.edges && this.props.car.equipmentSet.edges.length > 0 ?
                                        <div className="equipments">
                                            {
                                                this.props.car.equipmentSet.edges.map((equipment, i) =>
                                                    <Badge key={i} size="sm" className={"black"}>
                                                        {equipment.node.equipmentItems && equipment.node.equipmentItems.name ? equipment.node.equipmentItems.name : "--"}
                                                    </Badge>)
                                            }
                                            <InstallEquipment open={this.state.openModal === "InstallEquipment"} pk={this.props.match && this.props.match.params && this.props.match.params.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchCarQuery} />
                                            <Badge color="success" onClick={() => this.toggleModal("InstallEquipment")} id={"InstallEquipment"}>
                                                <i className="fa fa-lg fa-plus" aria-hidden="true" />
                                                <UncontrolledTooltip target={"InstallEquipment"} >Add Equipment</UncontrolledTooltip>
                                            </Badge>
                                        </div>
                                        :
                                        <p>No Equipment Found!</p>
                                    }
                                </Col>
                            </Row>
                        </div>
                        <br /><br />
                        <Row>
                            <Col>
                                <div className="blue-highlighted-header">Enable - Disable History</div>
                                <div className="info-container enable-disable">
                                    <Row>
                                        {enableDisableHistoryTable.map(tableHeader =>
                                            <Col key={tableHeader.id}><Label>{tableHeader.name}</Label></Col>
                                        )}
                                    </Row>
                                    {this.props.car && this.props.car.caractionSet && this.props.car.caractionSet.edges && this.props.car.caractionSet.edges.length > 0 ? this.props.car.caractionSet.edges.map((action, i) =>
                                        <Row>
                                            <Col><p>{action.node.actionType ? action.node.actionType : "--"}</p></Col>
                                            <Col><p>{action.node.dateAdded ? <DatetimeRenderer datetime={action.node.dateAdded} /> : "--"}</p></Col>
                                            <Col><p>{action.node.status ? action.node.status : "--"}</p></Col>
                                            <Col><p>{action.node.representative ? action.node.representative : "--"}</p></Col>
                                        </Row>) : <Row><Col>No History Found!</Col></Row>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <br /><br />
                        {this.props.car && this.props.car.currentAgreement &&
                            <>
                                <Row>
                                    <Col>
                                        <div className="green-highlighted-header">Active Car Rental</div>
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                                            <Row>
                                                <Col>
                                                    <Label>Driver Name</Label>
                                                    <p style={{ fontWeight: "600" }}>{this.props.car.currentAgreement.driver ? this.props.car.currentAgreement.driver.name : "---"} <Link color="primary" to={`/driver/${this.props.car.currentAgreement.driver.id}`} target="_blank" ><i className={"fa fa-external-link"}></i></Link> </p>
                                                </Col>
                                                <Col>
                                                    <Label>TLC License</Label>
                                                    <p>{this.props.car.currentAgreement.driver ? this.props.car.currentAgreement.driver.tlcLicense : "--"}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Start Date</Label>
                                                    <p>{this.props.car.currentAgreement.startDate ? moment(this.props.car.currentAgreement.startDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"}</p>
                                                </Col>
                                                <Col>
                                                    <Label>DMV License</Label>
                                                    <p> {this.props.car.currentAgreement.driver ? this.props.car.currentAgreement.driver.dmvLicense : "--"}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <br /><br />
                            </>
                        }
                        {this.state.activeReservation &&
                            <>
                                <Row>
                                    <Col>
                                        <div className="green-highlighted-header">Active Reservation</div>
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                                            <Row>
                                                <Col>
                                                    <Label>Driver Name</Label>
                                                    <p style={{ fontWeight: "600" }}>{this.state.activeReservation && this.state.activeReservation.node && this.state.activeReservation.node.driver ? this.state.activeReservation.node.driver.name : "--"}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Pickup Date</Label>
                                                    <p>{this.state.activeReservation && this.state.activeReservation.node && this.state.activeReservation.node.pickupDate ? moment(this.state.activeReservation.node.pickupDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Deposit</Label>
                                                    <p>{toTitleCase(this.state.activeReservation.depositScheme)} - {`$${this.state.activeReservation.depositAmount}`}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Payment Method</Label>
                                                    <p>{this.state.activeReservation && this.state.activeReservation.node && this.state.activeReservation.node.paymentMethod && this.state.activeReservation.node.paymentMethod.name ? this.state.activeReservation.node.paymentMethod.name : "--"}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Created By</Label>
                                                    <p>{this.state.activeReservation && this.state.activeReservation.node && this.state.activeReservation.node.rep ? this.state.activeReservation.node.rep.username : "--"}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <br /><br />
                            </>
                        }
                        <Row className="mb-5">
                            <Col>
                                <Nav tabs>
                                    <NavItem className={this.state.tab === "Buying Deal" ? "active" : "inactive"}>
                                        <NavLink className={this.state.dealType === "Buying Deal" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("dealType", "Buying Deal")}>
                                            BUYING DEAL
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={this.state.tab === "Selling Deal" ? "active" : "inactive"}>
                                        <NavLink className={this.state.dealType === "Selling Deal" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("dealType", "Selling Deal")}>
                                            SELLING DEAL
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                    {this.state.dealType === "Buying Deal" &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <Label>Seller</Label>
                                                    <Input disabled value={this.state.buyingDeal && this.state.buyingDeal.node.seller ? this.state.buyingDeal.node.seller.name : "--"} />
                                                </Col>
                                                <Col>
                                                    <Label>Buyer</Label>
                                                    <Input disabled value={this.state.buyingDeal && this.state.buyingDeal.node.buyer ? this.state.buyingDeal.node.buyer.name : "--"} />
                                                </Col>
                                                <Col>
                                                    <Label>Car Price</Label>
                                                    <Input disabled value={this.state.buyingDeal && this.state.buyingDeal.node.carPrice ? `$${this.state.buyingDeal.node.carPrice}` : "--"} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Label>Date Added</Label>
                                                    <Input disabled value={this.state.buyingDeal && this.state.buyingDeal.node.dateAdded ? moment(this.state.buyingDeal.node.dateAdded).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                                </Col>
                                                <Col>
                                                    <Label>Date Bought At</Label>
                                                    <Input disabled value={this.state.buyingDeal && this.state.buyingDeal.node.eventDate ? moment(this.state.buyingDeal.node.eventDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                                </Col>
                                                <Col>
                                                    <Label>Created By</Label>
                                                    <Input disabled value={this.state.buyingDeal && this.state.buyingDeal.node.metadata ? this.fetchMetaData(this.state.buyingDeal.node.metadata, "created_by") : "--"} />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {this.state.dealType === "Selling Deal" &&
                                        <>
                                            {this.state.sellingDeal ?
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <Label>Seller</Label>
                                                            <Input disabled value={this.state.sellingDeal && this.state.sellingDeal.node.seller ? this.state.sellingDeal.node.seller.name : "--"} />
                                                        </Col>
                                                        <Col>
                                                            <Label>Buyer</Label>
                                                            <Input disabled value={this.state.sellingDeal && this.state.sellingDeal.node.buyer ? this.state.sellingDeal.node.buyer.name : "--"} />
                                                        </Col>
                                                        <Col>
                                                            <Label>Car Price</Label>
                                                            <Input disabled value={this.state.sellingDeal && this.state.sellingDeal.node.carPrice ? this.state.sellingDeal.node.carPrice : "--"} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Label>Date Added</Label>
                                                            <Input disabled value={this.state.sellingDeal && this.state.sellingDeal.node.dateAdded ? moment(this.state.sellingDeal.node.dateAdded).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                                        </Col>
                                                        <Col>
                                                            <Label>Date Sold At</Label>
                                                            <Input disabled value={this.state.sellingDeal && this.state.sellingDeal.node.eventDate ? moment(this.state.sellingDeal.node.eventDate).tz("America/New_York").format(moment.HTML5_FMT.DATE) : "--"} />
                                                        </Col>
                                                        <Col>
                                                            <Label>Created By</Label>
                                                            <Input disabled value={this.state.sellingDeal && this.state.sellingDeal.node.metadata ? this.fetchMetaData(this.state.sellingDeal.node.metadata, "created_by") : "--"} />
                                                        </Col>

                                                    </Row>
                                                </> :
                                                <Row>
                                                    <Col className="d-flex justify-content-start">
                                                        <p>No Selling Information</p>
                                                    </Col>
                                                    <Col className="d-flex justify-content-end">
                                                        <Button className="primary" onClick={() => this.toggleModal("createSellingDeal")}>SELL A CAR&nbsp;&nbsp;<i id={"createSellingDeal"} className="fa fa-plus" aria-hidden="true"></i></Button>
                                                        {this.state.openModal === "createSellingDeal" && <CarDealModal carDetailPage car={this.props.car} open={this.state.openModal === "createSellingDeal"} handleClose={() => this.toggleModal("createSellingDeal")} refetchQuery={this.props.refetchCarQuery} />}
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </>}
                </div>
            </>
        )
    }
}


export default compose(
    withApollo,
    graphql(AllCarModelGroupTypes, { props({ data: { allCarModelGroupTypes } }) { return { allCarModelGroupTypes } } }),
    graphql(AllCarModelsQuery, { props({ data: { allCarModels, fetchMore } }) { return { allCarModels } } }),
    graphql(AllBranchesQuery, {
        options: { fetchPolicy: 'cache-and-network' }, props({ data: { allBranches, fetchMore } }) {
            return {
                allBranches,
                refetchAllBranches: () => {
                    return fetchMore({
                        query: AllBranchesQuery,
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allBranches.edges
                            const pageInfo = fetchMoreResult.allBranches.pageInfo
                            return {
                                allBranches: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allBranches.__typename
                                },
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(CarDetailQuery, {
        options: ({ match }) => ({ variables: { pk: match && match.params && match.params.pk } }),
        props({ data: { car, loading, refetch, variables } }) {
            return {
                car, loading, variables,
                refetchCarQuery: () => {
                    return refetch({
                        query: CarDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { car: fetchMoreResult.car }
                        },
                    })
                },
            }
        }
    }),
)(OverviewTab)
