import gql from 'graphql-tag'

const LocationQuery = gql`query LocationQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList:allCarLocations(first:$first, after:$cursor, searchTerm:$searchTerm, orderBy:$orderBy, includeInactive:true){
        edges{
            node{
                id
                pk
                name
                address
                isPickupLocation
                isReturnLocation
                isActive
                driverCanBookOnline
                defaultReturnLocation {
                    id
                    name
                }
                branch{
                    id
                    name
                }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllLocationsQuery = gql`query AllLocationsQuery{
    dataList:allCarLocations{
        edges{
            node{
                id
                pk
                name
            }
        }
    }
}`

const AllBranchesQuery = gql`query AllBranchesQuery{
    dataList:allBranches {
        edges {
            node {
                id
                name
            }
        }
    }
}`

const CarDealEntityQuery = gql`query CarDealEntityQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList:allCarDealEntities(first:$first, after: $cursor, searchTerm:$searchTerm, orderBy: $orderBy){
        edges{
            node{
                id
                name
                entityType
                isActive
                dateAdded
                dateModified
                pk
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const CarBaseQuery = gql`query CarBaseQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList:allBase(first:$first, after: $cursor, name_Icontains:$searchTerm, orderBy: $orderBy){
        edges{
            node{
                id
                pk
                name
                isActive
                dateAdded
                dateModified
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const CarDealEntityTypes = gql`query CarDealEntityTypes{
    dataList: __type(name: "CarDealEntityEntityType") {
      edges: enumValues {
          name
          description
      }
    }
}`

const InsurancePolicyStatuses = gql`query InsurancePolicyStatuses{
    dataList: __type(name: "InsurancePolicyStatus") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AllBrokersQuery = gql`{
    dataList:allBrokers(isActive:true){
      edges{
        node{
          name
          id
        }
      }
    }
}`

const AllOwnersQuery = gql`query AllOwnersQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
      dataList: allOwners(first:$first, after: $cursor, name_Icontains:$searchTerm, orderBy: $orderBy){
          edges{
            node{
                id
                name
                pk
                isActive
                dateAdded
                dateModified
            }
          }
          pageInfo{
              hasNextPage
              endCursor
          }
      }
  }`

const AllCarIssueCategoriesQuery = gql`query AllCarIssueCategoriesQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: allCarIssueCategories(first:$first, after: $cursor, category_Icontains:$searchTerm, orderBy: $orderBy){
        edges{
          node{
            id
            pk
            dateAdded
            dateModified
            category
            description
            requiredClosingProof
            outOfService
            type
          }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllInsurancePoliciesQuery = gql`query AllInsurancePoliciesQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy:[String]){
    dataList: allInsurancePolicies(first:$first, after: $cursor, searchTerm:$searchTerm, orderBy: $orderBy){
        edges{
          node{
            id
            pk
            dateAdded
            dateModified
            policyNumber
            broker{
                id
                name
            }
            startDate
            endDate
            status
          }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllVendorsQuery = gql`query AllVendorsQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: allVendors(first:$first, after: $cursor, searchTerm: $searchTerm, orderBy: $orderBy){
        edges{
            node{
                name
                address
                notes
                isActive
                dateAdded
                dateModified
                id
                pk
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllPlatesQuery = gql`query AllPlatesQuery($cursor: String, $first: Int!, $searchTerm: String){
    dataList: allPlates(first:$first, after: $cursor, dmvPlate_Icontains:$searchTerm,){
        edges{
            node{
                pk
                id
                dmvPlate
                isActive
                isAvailable
                dateAdded
                dateModified
                fhv
                fhvExpiration
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllEquipmentItemsGroup = gql`query AllEquipmentItemsGroup{
    dataList: allEquipmentItemsGroup {
        edges {
            node {
                id
                name
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllEquipmentItems = gql`query AllEquipmentItems($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: allEquipmentItems(first:$first, after: $cursor, searchTerm:$searchTerm, orderBy:$orderBy){
        edges{
            node{
                id
                pk
                name
                description
                equipmentItemsGroup {
                    id
                    name
                }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllBrokers = gql`query AllBrokers($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: allBrokers(first:$first, after: $cursor, name_Icontains:$searchTerm, orderBy : $orderBy){
        edges{
            node{
                id
                pk
                name
                dateAdded
                dateModified
                address
                isActive
                isMain
                notes
                isUnderwriter
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const GlobalSettings = gql`query GlobalSettings($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: globalSettings(first:$first, after: $cursor, settingsName_Icontains:$searchTerm, orderBy : $orderBy){
        edges{
            node{
                id
                pk
                settingsName
                settingsValue
                settingsNameDisplay
                dateAdded
                dateModified
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllSystemUsers = gql`query AllSystemUsers($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: allReps(first:$first, after: $cursor, username_Icontains:$searchTerm, orderBy : $orderBy){
        edges{
            node{
                id
                pk
                isStaff
                username
                dateJoined
                isSuperuser
                userPermissions {
              	  edges {
              	    node {
                      codename
              	      id
              	    }
              	  }
              	}
                groups {
                    edges { 
                        node {
                            name
                            id
                        }
                    }
                }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const AllChargeTypes = gql`query AllChargeTypes($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String]){
    dataList: allChargeTypes(first:$first, after: $cursor, name_Icontains:$searchTerm, orderBy : $orderBy){
        edges {
            node {
                id
                pk
                name
                increaseBalance
                description
                defaultAmount
                maximumAmount
                minimumAmount
                dateAdded
                dateModified
                bucket
                category
                isActive
                dueDateSchedule
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const ChargeTypeBucket = gql`query ChargeTypeBucket{
    dataList: __type(name: "ChargeTypeBucket") {
      edges: enumValues {
          name
          description
      }
    }
}`


const ChargeTypeCategory = gql`query ChargeTypeCategory{
    dataList: __type(name: "ChargeTypeCategory") {
      edges: enumValues {
          name
          description
      }
    }
}`

const ChargeTypeDueDateSchedule = gql`query ChargeTypeDueDateSchedule{
    dataList: __type(name: "ChargeTypeDueDateSchedule") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AllGroupTypes = gql`query AllGroupTypes($cursor: String, $first: Int!, $orderBy: [String], $searchTerm: String){
    dataList: allGroupTypes(first:$first, after: $cursor, orderBy : $orderBy, searchTerm: $searchTerm){
        edges {
            node {
                id
                pk
                name
                description
                isActive
                defaultAmount
                maximumAmount
                minimumAmount
                dateAdded
                dateModified
                chargeType {
                    id
                    name
                }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`

const ChargeTypes = gql`query ChargeTypes{
    dataList:allChargeTypes{
        edges{
            node{
                id
                name
            }
        }
    }
}`

const AgreementTypeChargeType = gql`query AgreementTypeChargeType{
    dataList: __type(name: "AgreementTypeChargeType") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AgreementTypeDepositScheme = gql`query AgreementTypeDepositScheme{
    dataList: __type(name: "AgreementTypeDepositScheme") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AgreementTypeBillingDay = gql`query AgreementTypeBillingDay{
    dataList: __type(name: "AgreementTypeBillingDay") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AllAgreementTypes = gql`query AllAgreementTypes($cursor: String, $first: Int, $orderBy: [String], $searchTerm: String){
    dataList: allAgreementTypes(first:$first, after: $cursor, orderBy : $orderBy, searchTerm: $searchTerm){
        edges{
            node{
                id
                pk
                name
                salesTax
                rentalSalesTax
                requiredDeposit
                requireEndDate
                depositScheme
                billingDay
                dueDateOffset
                chargeType
                customerType
                chargeFrequency
                chargeFrequencyUnit
                initialChargeFrequency
                dateAdded
                dateModified
                notes
                isActive
                branch{
                    id
                    name
                }
            }
        }
    }
}`

const AgreementTypeChargeFrequencyUnit = gql`query AgreementTypeChargeFrequencyUnit{
    dataList: __type(name: "AgreementTypeChargeFrequencyUnit") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AgreementTypeCustomerType = gql`query AgreementTypeCustomerType{
    dataList: __type(name: "AgreementTypeCustomerType") {
      edges: enumValues {
          name
          description
      }
    }
}`

const RegistrationTypeTypeFor = gql`query RegistrationTypeTypeFor{
    dataList: __type(name: "RegistrationTypeTypeFor") {
      edges: enumValues {
          name
          description
      }
    }
}`

const AllRegistrationTypes = gql`query AllRegistrationTypes($cursor: String, $first: Int!, $searchTerm: String){
    dataList: allRegistrationTypes(first:$first, after: $cursor, searchTerm: $searchTerm){
        edges{
            node{
                id
                dateAdded
                dateModified
                name
                stateCode
                typeFor
                isActive
                pk
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`
const AllTags = gql`query AllTags($cursor: String, $first: Int!, $orderBy: [String],  $searchTerm: String){
    dataList: allTags(first:$first, after: $cursor, orderBy: $orderBy, searchTerm: $searchTerm){
        edges{
            node{
              pk
              id
              name
              color
              slug
              removeUponReturn
              contentType {
                id
                model
                appLabel
              }
              tagType{
                    id
                    name
                }   
            }
          }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`
const AllTagTypes = gql`query AllTagTypes($searchTerm: String){
    dataList: allTagTypes(searchTerm: $searchTerm) {
      edges {
        node {
          id
          pk
          name
          description
        }
      }
    }
  }
`
const AllContentTypes = gql`query AllContentTypes{
    dataList: allContentTypes {
      edges {
        node {
            id
            pk
            appLabel
            name: model
        }
      }
    }
  }
`

const AllDriverDocumentTypes = gql`query AllDriverDocumentTypes($searchTerm: String){
    dataList: allDriverDocumentTypes(searchTerm: $searchTerm) {
      edges {
        node {
            id
            pk
            name: typeName
            dateAdded
            dateModified
            documentCategory {
                id
                name
            }
            allowMultiple
            visibleInDriverApp
        }
      }
    }
  }
`

const AllDocumentCategory = gql`query AllDocumentCategory($searchTerm: String){
    dataList: allDocumentCategory(searchTerm: $searchTerm) {
      edges {
        node {
            id
            pk   
            name
            dateAdded
            dateModified
			ddescription
        }
      }
    }
  }
`

const AllCarDocumentTypes = gql`query AllCarDocumentTypes($searchTerm: String){
    dataList: allCarDocumentTypes(searchTerm: $searchTerm) {
      edges {
        node {
            id
            pk
            requiresExpiration
            name: typeName
            dateAdded
            dateModified
            documentCategory {
                id
                name
            }
            allowMultiple
            visibleInDriverApp
        }
      }
    }
  }
`

const AllScheduleClosingDays = gql`query AllScheduleClosingDays($searchTerm: String){
    dataList: allScheduleClosingDays(searchTerm: $searchTerm) {
      edges {
        node {
            id
            pk
            dateAdded
            dateModified
            scheduleDate
            reason
            branch{
                id
                name
            }
        }
      }
    }
  }
`

const AllBusinessHours = gql`query AllBusinessHours($searchTerm: String){
    dataList: allBusinessHours(searchTerm: $searchTerm) {
      edges {
        node {
            id
            pk
            dayOfWeek
            openTime
            closeTime
            dateAdded
            dateModified
            branch{
                id
                name
            }
        }
      }
    }
  }
`


const AllSubscriptionTypes = gql`query AllSubscriptionTypes($cursor: String, $first: Int, $orderBy: [String], $searchTerm: String){
    dataList: allSubscriptionTypes(first:$first, after: $cursor, orderBy: $orderBy, searchTerm: $searchTerm) {
        edges {
            node {
              id:pk
              pk
              dateAdded
              dateModified
              name
              customId
              description
              amount
              deductibleAmount
              interval
              isActive
              intervalFrequency
              retryLimit
              subscriptionManager
              agreementType {
                id
                name
              }
            }
          }
    }
  }
`

const AllReturnTimeSlots = gql`query AllReturnTimeSlots($cursor: String, $first: Int, $orderBy: [String], $searchTerm: String){
    dataList: allReturnTimeSlots(first:$first, after: $cursor, orderBy: $orderBy, searchTerm: $searchTerm) {
        edges {
            node {
              id
              pk
             __typename
             location {
               id
               name
               branch{
                id
                name
               }
             }
             startTime
             availableSlots
             dateAdded
             dateModified
            }
        }
    }
  }
`
const AllBlockedReasons = gql`query AllBlockedReasons($cursor: String, $first: Int, $orderBy: [String], $searchTerm: String){
    dataList: allBlockedReasons(first:$first, after: $cursor, orderBy: $orderBy, searchTerm: $searchTerm) {
        edges {
            node {
                id
                pk
                name
                description
            }
        }
    }
  }
`


const SubscriptionTypeInterval = gql`query SubscriptionTypeInterval{
    dataList: __type(name: "SubscriptionTypeInterval") {
      edges: enumValues {
          name
          description
      }
    }
}`

const SubscriptionTypeSubscriptionManager = gql`query SubscriptionTypeSubscriptionManager{
    dataList: __type(name: "SubscriptionTypeSubscriptionManager") {
      edges: enumValues {
          name
          description
      }
    }
}`
const CarIssueCategoryType = gql`query CarIssueCategoryType{
    dataList: __type(name: "CarIssueCategoryType") {
        edges: enumValues {
            name
            description
        }
    }
  }`
const BusinessHourDayOfWeek = gql`query BusinessHourDayOfWeek{
    dataList: __type(name: "BusinessHourDayOfWeek") {
      edges: enumValues {
          name
          description
      }
    }
}`


export {
    LocationQuery, CarDealEntityQuery, CarBaseQuery, CarDealEntityTypes,
    AllCarIssueCategoriesQuery, AllVendorsQuery, AllPlatesQuery,
    AllInsurancePoliciesQuery, AllBrokersQuery, InsurancePolicyStatuses,
    AllEquipmentItems, AllBrokers, GlobalSettings, AllSystemUsers,
    AllEquipmentItemsGroup, AllChargeTypes, ChargeTypeBucket,
    ChargeTypeCategory, AllGroupTypes, ChargeTypes, AllAgreementTypes,
    AgreementTypeChargeType, AgreementTypeChargeFrequencyUnit, BusinessHourDayOfWeek,
    AgreementTypeCustomerType, RegistrationTypeTypeFor, AllRegistrationTypes,
    AllBranchesQuery, AllTags, AllTagTypes, AllContentTypes, AllDriverDocumentTypes,
    AllDocumentCategory, AllCarDocumentTypes, AllScheduleClosingDays, AgreementTypeDepositScheme,
    AllSubscriptionTypes, SubscriptionTypeInterval, SubscriptionTypeSubscriptionManager,
    AllBusinessHours, CarIssueCategoryType, AllReturnTimeSlots, AllLocationsQuery, AllOwnersQuery,
    ChargeTypeDueDateSchedule, AgreementTypeBillingDay, AllBlockedReasons
}
