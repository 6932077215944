import React, { Component } from 'react'
import { compose, withApollo, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Progress
} from 'reactstrap'
import moment from 'moment'
import { HasPermissionsQuery } from "../../Functions/querys"
import "./PaymentScheduleModal.css"

const UpdatePaymentScheduleMutation = gql`
mutation updatePaymentSchedule($input: UpdatePaymentScheduleMutationInput!){
    updatePaymentSchedule(input:$input){
    ok
    errors{
      messages
    }
}
} `

const DeletePaymentScheduleMutation = gql`
mutation deletePaymentSchedule($input: DeletePaymentScheduleMutationInput!){
    deletePaymentSchedule(input:$input){
    ok
    errors{
      messages
    }
}
} `

const MINIMUM_SCHEDULE_DATETIME = moment().tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)
const MAXIMUM_SCHEDULE_DATETIME = moment().tz("America/New_York").add(5, 'day').format(moment.HTML5_FMT.DATETIME_LOCAL)
class UpdatePaymentSchedule extends Component {
    constructor(props) {
        super(props)

        this.state = {
            input: {
                "amountToCharge": this.props.value && this.props.value.amountToCharge ? this.props.value.amountToCharge : null,
                "scheduleDatetime": this.props.value && this.props.value.scheduleDatetime ? this.props.value.scheduleDatetime : null,
                "notes": this.props.value && this.props.value.notes ? this.props.value.notes : null,
            },
            error: null,
            loading: false
        }
    }

    updateInput = (e) => {
        if (this.props.driverId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["driverId"] = this.props.driverId
            input[name] = value
            this.setState({ input: input })
        }
    }
    updatePaymentSchedule = (e) => {
        try {
            this.setState({ loading: true })
            let mutationInput = this.state.input
            let scheduleDatetime = new Date(mutationInput["scheduleDatetime"])
            mutationInput["scheduleDatetimeInUtc"] = Math.floor(scheduleDatetime.getTime() / 1000)
            delete mutationInput["scheduleDatetime"]
            this.props.client.mutate({
                mutation: UpdatePaymentScheduleMutation,
                variables: { input: mutationInput },
            }).then((result) => {
                if (result && result.data && result.data.updatePaymentSchedule && result.data.updatePaymentSchedule.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                }
                else if (result && result.data && result.data.updatePaymentSchedule && result.data.updatePaymentSchedule.errors[0] && result.data.updatePaymentSchedule.errors[0].messages) {
                    this.setState({ error: result.data.updatePaymentSchedule.errors[0].messages, loading: false })
                }

                this.setState({ loading: false })
            }).catch((err) => {
                let error = "An error has occured " + err
                this.setState({ error: error, loading: false })
            })
        }
        catch (err) {
            let error = "An error has occured " + err
            this.setState({ error: error, loading: false })
        }

    }

    deletePaymentSchedule = (e) => {
        try {
            this.setState({ loading: true })
            this.props.client.mutate({
                mutation: DeletePaymentScheduleMutation,
                variables: { input: { driverId: this.props.driverId } },
            }).then((result) => {
                if (result && result.data && result.data.deletePaymentSchedule && result.data.deletePaymentSchedule.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                }
                else if (result && result.data && result.data.deletePaymentSchedule && result.data.deletePaymentSchedule.errors[0] && result.data.deletePaymentSchedule.errors[0].messages) {
                    this.setState({ error: result.data.deletePaymentSchedule.errors[0].messages, loading: false })
                }

                this.setState({ loading: false })
            }).catch((err) => {
                let error = "An error has occured " + err
                this.setState({ error: error, loading: false })
            })
        }
        catch (err) {
            let error = "An error has occured " + err
            this.setState({ error: error, loading: false })
        }

    }

    render() {
        return (
            <Modal isOpen={this.props.open} className="payment-schedule-modal">
                <ModalHeader toggle={this.toggle}>Update Scheduled Payment</ModalHeader>
                <ModalBody>
                    {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    <Row>
                        {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}

                        <Col xs={12}>
                            <FormGroup>
                                <Label for="amountToCharge">Amount to charge</Label>
                                <Input
                                    type={"number"}
                                    name="amountToCharge"
                                    id="amountToCharge"
                                    defaultValue={this.state.input.amountToCharge}
                                    onChange={this.updateInput}
                                    placeholder="Enter amount to charge"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="scheduleDatetime">Date & time to charge</Label>
                                <Input
                                    type="datetime-local"
                                    min={MINIMUM_SCHEDULE_DATETIME}
                                    max={MAXIMUM_SCHEDULE_DATETIME}
                                    defaultValue={moment(this.state.input.scheduleDatetime).tz("America/New_York").format(moment.HTML5_FMT.DATETIME_LOCAL)}
                                    onChange={this.updateInput}
                                    name="scheduleDatetime"
                                    id="scheduleDatetime"
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="notes">Payment schedule notes</Label>
                                <Input
                                    type={"text"}
                                    name="notes"
                                    id="notes"
                                    onChange={this.updateInput}
                                    defaultValue={this.state.input.notes}
                                    placeholder="Notes"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col xs={2} className="p-0 text-left">
                        <Button className="payment-schedule-modal-danger-btn" id={"deletePaymentSchedule"} onClick={this.deletePaymentSchedule} disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("delete_paymentschedule"))}>
                            Delete
                        </Button>
                        {!(this.props.hasPermissions && this.props.hasPermissions.includes("delete_paymentschedule")) && <UncontrolledTooltip target={"deletePaymentSchedule"}>Don't have enough permission to perform this action</UncontrolledTooltip>}
                    </Col>
                    <Col xs={10} className="p-0 text-right">
                        <Button className="payment-schedule-modal-primary-btn" onClick={this.updatePaymentSchedule} disabled={this.state.loading || (this.state.input.amountToCharge == 0 || this.state.input.amountToCharge < 1) || this.state.input.scheduleDatetime == null}>
                            Update
                        </Button>&nbsp;&nbsp;
                        <Button className="payment-schedule-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>
                    </Col>
                </ModalFooter>

            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["delete_paymentschedule"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, variables } }) {
            return { hasPermissions, variables }
        }
    })
)(UpdatePaymentSchedule)