import React, { Component } from 'react';
import {compose, graphql, withApollo} from 'react-apollo';
import moment from 'moment-timezone';
import gql from 'graphql-tag';
import { Popover,PopoverBody,PopoverHeader, Button, Form, FormGroup, Label, Input, Progress ,Alert
} from 'reactstrap';

const UpdateAgreementPromoMutation = gql`mutation updateAgreementPromoMutation($input: UpdateAgreementPromoMutationInput!){
    updateAgreementPromo(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`;

class UpdateAgreementPlan extends Component{
    constructor(props) {
        super(props);
        this.state = {
            error:null,
            input:{},
            loading:false
        };
    }
    updateAgreementPromo = () => {
        try{
            if(this.props.agreementPlan && this.props.agreementPlan.id){
                this.setState({loading:true})
                let input = this.state.input;
                input["agreementPromo"] = this.props.agreementPlan.id;

                this.props.client.mutate({
                    mutation: UpdateAgreementPromoMutation,
                    variables: {input},
                }).then((result)=>{
                    if(result && result.data && result.data.updateAgreementPromo && result.data.updateAgreementPromo.ok){
                        this.props.refetchQuery();
                        this.props.handleClose();
                        this.setState({input:{},loading:false,error:null})
                    }else{
                        let errorMessage = result && result.data && result.data.updateAgreementPromo && result.data.updateAgreementPromo.errors && result.data.updateAgreementPromo.errors.length>0 && result.data.updateAgreementPromo.errors[0].messages ? result.data.updateAgreementPromo.errors[0].messages.join("; ") : "An error has occured. Please retry or contact admin.";
                        this.setState({input:{},loading:false,error:errorMessage})
                    }
                }).catch((err)=>{
                    this.setState({loading:false,error:"An error has occured. Please retry or contact admin."})
                })
            }
        }catch(err){
            this.setState({loading:false,error:"An error has occured. Please retry or contact admin."})
        }
        
    }
    updateInput = (e) => {
        let name=e.target.name
        let value = e.target.value;
        let input = this.state.input;
        input[name] = value;
        this.setState({input:input})
    }    
    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    {this.state.error&&<Alert color="danger">{this.state.error}</Alert>}
                    <PopoverBody>
                        <Form>
                        <FormGroup>
                        {this.props.type === "date" &&
                        <>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            <Input type="date" name={this.props.name} id={this.props.name} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} defaultValue={this.props.value} onChange={this.updateInput} />
                        </>
                        }
                        </FormGroup>
                        <FormGroup>
                        {this.props.type === "number" &&
                        <>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            <Input type={this.props.type} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput}/>
                        </>
                        }
                        </FormGroup>
                        {this.state.loading?<Progress animated color="info" value={100} />:<Button type="submit" size="sm" onClick={this.updateAgreementPromo}>Submit</Button>}
                        </Form>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo
)(UpdateAgreementPlan);

