import gql from 'graphql-tag'

const CreateCarLocation = gql`mutation CreateCarLocation($input: CreateCarLocationMutationInput!){
    result:createCarLocation(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateCarLocation = gql`mutation UpdateCarLocation($input: UpdateCarLocationMutationInput!){
    result:updateCarLocation(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateCarDealEntity = gql`mutation CreateCarDealEntity($input: CreateCarDealEntityMutationInput!){
    result:createCarDealEntity(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateCarDealEntity = gql`mutation UpdateCarDealEntity($input: UpdateCarDealEntityMutationInput!){
    result:updateCarDealEntity(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`


const CreateOwner = gql`mutation CreateOwner($input: CreateOwnerMutationInput!){
    result:createOwner(input: $input){
        errors{
            field
            messages
        }
    }
}`

const UpdateOwner = gql`mutation UpdateOwner($input: UpdateOwnerMutationInput!){
    result:updateOwner(input: $input){
        errors{
            field
            messages
        }
    }
}`

const CreateGlobalSettings = gql`mutation CreateGlobalSettings($input: CreateGlobalSettingsMutationInput!){
    result:createGlobalSettings(input: $input){
        errors{
            field
            messages
        }
    }
}`

const UpdateGlobalSettings = gql`mutation UpdateGlobalSettings($input: UpdateGlobalSettingsMutationInput!){
    result:updateGlobalSettings(input: $input){
        errors{
            field
            messages
        }
    }
}`

const CreateCarEquipmentItem = gql`mutation CreateCarEquipmentItem($input: CreateCarEquipmentItemsMutationInput!){
    result:createCarEquipmentItem(input: $input){
        errors{
            field
            messages
        }
    }
}`

const UpdateCarEquipmentItem = gql`mutation UpdateCarEquipmentItem($input: UpdateCarEquipmentItemsMutationInput!){
    result:updateCarEquipmentItem(input: $input){
        errors{
            field
            messages
        }
    }
}`


const CreateInsurancePolicy = gql`mutation CreateInsurancePolicy($input: CreateInsurancePolicyMutationInput!){
    result:createInsurancePolicy(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateInsurancePolicy = gql`mutation UpdateInsurancePolicy($input: UpdateInsurancePolicyMutationInput!){
    result:updateInsurancePolicy(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateCarIssueCategory = gql`mutation CreateCarIssueCategory($input: CreateCarIssueCategoryMutationInput!){
    result:createCarIssueCategory(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateCarIssueCategory = gql`mutation UpdateCarIssueCategory($input: UpdateCarIssueCategoryMutationInput!){
    result:updateCarIssueCategory(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateVendor = gql`mutation CreateVendor($input: CreateVendorMutationInput!){
    result:createVendor(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateVendor = gql`mutation updateVendor($input: UpdateVendorMutationInput!){
    result:updateVendor(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateBase = gql`mutation CreateBase($input: CreateBaseMutationInput!){
    result:createBase(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateBase = gql`mutation UpdateBase($input: UpdateBaseMutationInput!){
    result:updateBase(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateBroker = gql`mutation CreateBroker($input: CreateBrokerMutationInput!){
    result:createBroker(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateBroker = gql`mutation UpdateBroker($input: UpdateBrokerMutationInput!){
    result:updateBroker(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateChargeType = gql`mutation CreateChargeType($input: CreateChargeTypeMutationInput!){
    result:createChargeType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateChargeType = gql`mutation UpdateChargeType($input: UpdateChargeTypeMutationInput!){
    result:updateChargeType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateGroupType = gql`mutation CreateChargeType($input: CreateGroupTypeMutationInput!){
    result:createGroupType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateGroupType = gql`mutation CreateChargeType($input: UpdateGroupTypeMutationInput!){
    result:updateGroupType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateAgreementType = gql`mutation CreateChargeType($input: CreateAgreementTypeMutationInput!){
    result:createAgreementType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateAgreementType = gql`mutation UpdateAgreementType($input: UpdateAgreementTypeMutationInput!){
    result:updateAgreementType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateRegistrationType = gql`mutation CreateRegistrationType($input: CreateRegistrationTypeMutationInput!){
    result:createRegistrationType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateColorTag = gql`mutation CreateColorTag($input: CreateColorTagInput!){
    result:createColorTag(input: $input){
        ok
        colorTag{
            id
            name
            color
        }
        errors{
            field
            messages
        }
    }
}`
const UpdateColorTag = gql`mutation UpdateColorTag($input: UpdateColorTagInput!){
    result:updateColorTag(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`


const CreateDriverDocumentType = gql`mutation CreateDriverDocumentType($input: CreateDriverDocumentTypeInput!){
    result:createDriverDocumentType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateDriverDocumentType = gql`mutation UpdateDriverDocumentType($input: UpdateDriverDocumentTypeInput!){
    result:updateDriverDocumentType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`


const CreateCarDocumentType = gql`mutation CreateCarDocumentType($input: CreateCarDocumentTypeInput!){
    result:createCarDocumentType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateCarDocumentType = gql`mutation UpdateCarDocumentType($input: UpdateCarDocumentTypeInput!){
    result:updateCarDocumentType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateDocumentCategory = gql`mutation CreateDocumentCategory($input: CreateDocumentCategoryInput!){
    result:createDocumentCategory(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateDocumentCategory = gql`mutation UpdateDocumentCategory($input: UpdateDocumentCategoryInput!){
    result:updateDocumentCategory(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateTagType = gql`mutation CreateTagType($input: CreateTagTypeInput!){
    result:createTagType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateTagType = gql`mutation UpdateTagType($input: UpdateTagTypeInput!){
    result:updateTagType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateScheduledClosingDay = gql`mutation CreateScheduledClosingDay($input: CreateScheduledClosingDayInput!){
    result:createScheduleClosingDay(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateScheduledClosingDay = gql`mutation UpdateScheduledClosingDay($input: UpdateScheduledClosingDayInput!){
    result:updateScheduleClosingDay(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateSubscriptionType = gql`mutation CreateSubscriptionTypeMutationInput($input: CreateSubscriptionTypeMutationInput!){
    result:createSubscriptionType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateSubscriptionType = gql`mutation UpdateSubscriptionTypeMutationInput($input: UpdateSubscriptionTypeMutationInput!){
    result:updateSubscriptionType(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateBusinessHour = gql`mutation CreateBusinessHourInput($input: CreateBusinessHourInput!){
    result:createBusinessHour(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`
const CreateReturnTimeSlot = gql`mutation CreateReturnTimeSlotMutationInput($input: CreateReturnTimeSlotMutationInput!){
    result:createReturnTimeSlot(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateBusinessHour = gql`mutation UpdateBusinessHourInput($input: UpdateBusinessHourInput!){
    result:updateBusinessHour(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`
const UpdateReturnTimeSlot = gql`mutation UpdateReturnTimeSlotMutationInput($input: UpdateReturnTimeSlotMutationInput!){
    result:updateReturnTimeSlot(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const CreateBlockedReason = gql`mutation CreateBlockedReasonMutationInput($input: CreateBlockedReasonMutationInput!){
    result:createBlockedReason(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`

const UpdateBlockedReason = gql`mutation UpdateBlockedReasonInput($input: UpdateBlockedReasonMutationInput!){
    result:updateBlockedReason(input: $input){
        ok
        errors{
            field
            messages
        }
    }
}`


export {
    CreateCarDealEntity, UpdateCarDealEntity, CreateCarLocation,
    CreateOwner, CreateInsurancePolicy, UpdateCarEquipmentItem,
    CreateCarIssueCategory, CreateVendor, UpdateVendor, CreateBase,
    UpdateCarLocation, CreateBroker, UpdateBroker, CreateCarEquipmentItem,
    CreateGlobalSettings, UpdateGlobalSettings, UpdateInsurancePolicy,
    UpdateBase, UpdateOwner, UpdateCarIssueCategory, CreateChargeType,
    UpdateChargeType, CreateGroupType, UpdateGroupType, CreateAgreementType,
    UpdateAgreementType, CreateRegistrationType, UpdateColorTag, CreateColorTag,
    CreateDriverDocumentType, UpdateDriverDocumentType, CreateCarDocumentType,
    UpdateCarDocumentType, CreateDocumentCategory, UpdateDocumentCategory,
    CreateTagType, UpdateTagType, CreateScheduledClosingDay, UpdateScheduledClosingDay,
    CreateSubscriptionType, UpdateSubscriptionType, CreateBusinessHour, UpdateBusinessHour,
    CreateReturnTimeSlot, UpdateReturnTimeSlot, CreateBlockedReason, UpdateBlockedReason
}
