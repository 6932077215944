import React, { Component } from 'react';
import {compose, graphql, withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import { Popover,PopoverBody,PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert
} from 'reactstrap';
import {EnumQuery} from '../../Functions/querys';

const UpdatePaymentPlan = gql`
mutation updatePaymentPlan($input: UpdatePaymentPlanMutationInput!){
updatePaymentPlan(input:$input){
    ok
    errors{
      messages
    }
}
} `;


class UpdatePaydownInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            error:null,
            paymentPlan:props.paymentPlan,
            input:{},
            loading:false
        };
    }

    updatePaydown = () => {
        if(this.state.input.paymentPlanId){
            this.setState({loading:true})
            let input = this.state.input;
            this.props.client.mutate({
                mutation: UpdatePaymentPlan,
                variables: {input},
            }).then((result)=>{
                if(result && result.data && result.data.updatePaymentPlan && result.data.updatePaymentPlan.ok){
                    this.props.refetchQuery();
                    this.props.handleClose();
                    this.setState({input:{},loading:false,error:null})
                }else{
                    this.setState({loading:false,error:"An error has occurred. Please check your input or contact admin."}) 
                }
            }).catch((err)=>{
                this.setState({loading:false,error:"An error has occurred. Please contact admin."})
            })
        }else{
        }
    }
    updateInput = (e) => {
        if(this.state.paymentPlan){
            let name = e.target.name;
            let value = e.target.value;
            let input = this.state.input;
            input["paymentPlanId"] = this.state.paymentPlan.id;
            input[name] = value;
            this.setState({input:input})
        }  
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.paymentPlan !== prevProps.paymentPlan) {
            let paymentPlan = this.props.paymentPlan;
            this.setState({paymentPlan:paymentPlan});
        }
    }
    
    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{float:"right"}} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error&&<Alert color="danger">{this.state.error}</Alert> }
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.name==="reason"?
                                    <Input type={"select"} name={this.props.name} id={this.props.name} onChange={this.updateInput}>
                                        <option value=""></option>
                                        {this.props.paymentPlanReasons && this.props.paymentPlanReasons.states.map((reason,i) =>
                                            <option key={i} value={reason.description}>{reason.description}</option>
                                        )}
                                    </Input>
                            :
                                <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength?this.props.maxLength:200} 
                                    name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                    placeholder={this.props.title} onChange={this.updateInput}
                                />
                            }
                            </FormGroup>
                        {this.state.loading?<Progress animated color="info" value={100} />:<Button type="button" size="sm" onClick={this.updatePaydown}>Submit</Button>}
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, {props({data: {paymentPlanReasons}}) { return {paymentPlanReasons}}}),
)(UpdatePaydownInfo);