import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import ListingFilters from '../../Material/ListingFilters'
import '../../Drivers/DriversNew/DriversListContainer.css'
import { debounce } from 'lodash'
import toTitleCase from '../../Functions/toTitleCase'
import renderEnumToInt from "../../Functions/renderEnumToInt"
import moment from "moment"
import { Popover, PopoverBody, PopoverHeader, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap"
import { DateRange } from "react-date-range"
import MassMessageModal from "../../Material/MassMessageModal"
import RepAssignmentModal from "../../Material/RepAssignmentModal"
import updateFilters from "../../Functions/updateFilters"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"

const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 11){
      edges {
        node {
          id
          name
          color
        }
      }
    }
}`

const assignDriverToRepMutation = gql`
mutation CreateNewAssignment ($repID:ID!, $drivers:ID!){
    createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        assignment{
            id
        }
    }
}`

const AllAgreementTypes = gql`{
    optionsList:allAgreementTypes{
      edges{
        node{
            id
            name
        }
      }
    }
}`


class AvailableCarsHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            filterValues: this.props.filterValues
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            {
                type: "boolean", name: "isReady", title: "Is Ready", optionOne: "YES",
                optionTwo: "NO", showFilter: activeTab === "Available Cars"
            },
            { type: "select", name: "returnType", title: "Return Type", options: ["Confirmed Returns", "Future Returns"].map(item => ({ value: item, label: item })), placeholder: "Filter By Return Stage", valueSelector: "name", labelSelector: "name", isMulti: false, showFilter: activeTab === "Scheduled Returns" },
            { type: "select", name: "agreementTypes", title: "Agreement Types", optionsQuery: AllAgreementTypes, valueSelector: "id", labelSelector: "name", isMulti: true, placeholder: "Filter By Agreement Types", showFilter: activeTab === "Scheduled Returns" },
            { type: "select", name: "carTags", title: "Car Tags", optionsQuery: TagsQuery, placeholder: "Filter By Car Tags", valueSelector: "name", labelSelector: "name", isMulti: true, showFilter: activeTab === "Available Cars" },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = debounce((searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }, 500)
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    unAssignDrivers = () => {
        let input = {
            repID: "none",
            drivers: this.props.selectedDrivers
        }
        this.props.client.mutate({
            mutation: assignDriverToRepMutation,
            variables: input,
        }).then((result) => {
            this.props.refetchQuery()
        })
    };
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range")
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        return Array.isArray(value) ? value.join(", ") : value
    }
    getFilters = (activeTab) => {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector], label: options.node[filter.labelSelector]
                        }))
                    }
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        this.getFilters(this.props.activeTab)
    }
    componentDidUpdate(prevProps) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.getFilters(this.props.activeTab)
        }
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector], label: options.node[filter.labelSelector]
                        }))
                    } else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? options[filter.valueSelector] : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        if (this.props.activeTab)
            this.setConfigurations()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.setConfigurations()
        }

        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    applyDateFitler = () => {
        this.setState({ showDateRangePopup: true })
        this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }] })
    }
    clearDateFilter = () => {
        let filterValues = this.props.filterValues
        filterValues = delete filterValues['selectionRange']
        this.props.setFilterValues({ ...this.props.filterValues })
    }

    render() {
        return (
            <Row className="mb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" &&
                    <MassMessageModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedIds} />
                }
                {this.state.openModal === "RepAssignmentModal" && <RepAssignmentModal handleClose={() => this.toggleModal("RepAssignment")} open={this.state.openModal === "RepAssignment"} drivers={this.props.selectedIds} />}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                {this.props.activeTab === "Scheduled Returns" && <Col xs={3}>
                    <InputGroup className="mb-0 date-range-group">
                        <InputGroupAddon addonType="prepend">
                            <a id="customDateSelection" onClick={this.applyDateFitler}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                        </InputGroupAddon>
                        <Input disabled placeholder="Select Custom Date Range" value={this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate ? moment(this.props.filterValues.selectionRange[0].startDate).tz("America/New_York").format("ddd, MMM D YYYY") + " - " + moment(this.props.filterValues.selectionRange[0].endDate).tz("America/New_York").format("ddd, MMM D YYYY") : ""} />
                        {this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate && <i className="fa fa-times-circle-o reset-date-range" onClick={() => this.clearDateFilter()} aria-hidden="true"></i>}
                        <Popover placement="bottom" isOpen={this.state.showDateRangePopup} target="customDateSelection" toggle={() => this.setState({ showDateRangePopup: false })}>
                            <PopoverHeader className="text-center">Select Custom Date Range</PopoverHeader>
                            <PopoverBody>
                                <DateRange ranges={this.props.filterValues && this.props.filterValues.selectionRange} onChange={(ranges) => this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [ranges.selection] })} editableDateInputs={true} moveRangeOnFirstSelection={true} />
                            </PopoverBody>
                        </Popover>
                    </InputGroup>
                </Col>}
                <Col xs={{ size: this.props.activeTab === "Scheduled Returns" ? 5 : 8 }} className="text-right mt-2">
                    {this.props.activeTab === "Scheduled Returns" && this.props.selectedIds.length > 0 && <>
                        <a className="driver-list-button" onClick={this.unAssignDrivers}>
                            Unassign Reps | <i className="fa fa-minus" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="driver-list-button" onClick={() => this.toggleModal("RepAssignmentModal")}>
                            Assign Reps | <i className="fa fa-plus" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="driver-list-button" onClick={() => this.toggleModal("MassMessageModal")}>
                            Message Drivers | <i className="fa fa-commenting-o" aria-hidden="true"></i>
                        </a>
                    </>}
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedIds}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
)(AvailableCarsHeader)
