import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import MassMessageModal from "../Material/MassMessageModal"
import ListingFilters from '../Material/ListingFilters'
import { debounce } from 'lodash'
import toTitleCase from "../Functions/toTitleCase"
import { Row, Col, Input } from "reactstrap"
import renderEnumToInt from "../Functions/renderEnumToInt"
import BulkRepAssignment from "../Material/BulkRepAssignment"
import { HasPermissionsQuery } from "../Functions/querys"
import updateFilters from "../Functions/updateFilters"
import getFilterValuesFromQueryString from "../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../Functions/handleConfigurationChange"
import TablePreferencePicker from "../Material/TablePreferencePicker"

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 13){
        edges{
            node{
                id
                name
                color
            }
        }
    }
}`

const DriverInsuranceStatusStatus = gql`query   {
    optionsList:__type(name: "DriverInsuranceStatusStatus") {
        states: enumValues {
            name
            description
        }
    }
}`


const RepAssignment = gql`
mutation BulkRepAssignment ($input:BulkRepAssignmentMutationInput!){
    bulkRepAssignment(input:$input){
        errors{
            messages
        }
        ok
    }
}`

const DriverInsuranceTypeQuery = gql`query DriverInsuranceTypeQuery{
    optionsList: allDriverInsuranceType(isActive:true){
        edges{
            node{
                id
                name
                broker{
                    id
                    name
                }
            }
        }
    }
}`


class InsuranceListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            filterValues: this.props.filterValues
        }
    }

    getFilterConfigurations = (activeTab) => {
        return [
            {
                type: "select", name: "statuses", title: "Insurance Status",
                optionsQuery: DriverInsuranceStatusStatus, placeholder: "Filter By Insurance Status",
                valueSelector: "name", extractValue: true, labelSelector: "description", showFilter: ["All Insurances"].includes(activeTab)
            },
            {
                type: "select", name: "types", title: "Insurance Types",
                optionsQuery: DriverInsuranceTypeQuery, placeholder: "Filter By Insurance Types",
                valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: true
            },
            {
                type: "boolean", name: "hasMissingDocs", title: "Filter By Missing Docs", optionOne: "YES",
                optionTwo: "NO", showFilter: ["All Insurances"].includes(activeTab)
            },
            {
                type: "boolean", name: "hasRental", title: "Drivers With Rental", optionOne: "YES",
                optionTwo: "NO", showFilter: true
            },
            { type: "boolean", name: "inOffice", title: "Filter By Driver In Office", optionOne: "YES", optionTwo: "NO", placeholder: "Driver In Office", showFilter: true },
            {
                type: "select", name: "driverStages", title: "Driver Stage", options: [
                    { value: 6, label: "Active" },
                    { value: 10, label: "Inactive", },
                    { value: 1, label: "Applied" },
                    { value: 0, label: "Didnt Take Car" },
                ], placeholder: "Filter By Stage", showFilter: ["All Insurances"].includes(activeTab), isMulti: true
            },
            {
                type: "select", name: "assignedTo", title: "Assigned Reps", valueSelector: "id", labelSelector: "username",
                optionsQuery: RepsQuery, variables: { groups: ["Sales Team"] }, placeholder: "Filter By Assigned Reps",
                showFilter: true
            },
            { type: "select", name: "driverTags", title: "Driver Tags", optionsQuery: TagsQuery, placeholder: "Filter By Driver Tags", valueSelector: "name", labelSelector: "name", isMulti: true, showFilter: true },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = debounce((searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }, 500)
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range")
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector], label: filter['name'] === "types" && options.node.broker ? options.node[filter.labelSelector] + " ( " + options.node.broker.name + " )" : options.node[filter.labelSelector]
                        }))
                    } else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        if (this.props.activeTab)
            this.setConfigurations()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.setConfigurations()
        }
        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    unAssignReps = () => {
        let input = {
            objectIds: this.props.selectedInsurances.map(insurance => insurance.id),
        }
        this.props.client.mutate({
            mutation: RepAssignment,
            variables: { input }
        }).then((result) => {
            this.props.refetchQuery()
        })
    }
    render() {
        return (
            <Row className="pb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedInsurances && this.props.selectedInsurances.length > 0 ? this.props.selectedInsurances.map(insurance => insurance.driver.id) : null} />}
                {this.state.openModal === "RepAssignmentModal" && <BulkRepAssignment
                    open={this.state.openModal === "RepAssignmentModal"}
                    title={"Assign " + this.props.selectedInsurances.length + " Selected Insurances to Reps"}
                    selectedObjectIds={this.props.selectedInsurances.map(insurance => insurance.id)}
                    handleClose={() => this.toggleModal(false)}
                    refetchQuery={this.props.refetchQuery}
                    permissions={["custom_can_assign_insurance"]}
                />}
                {this.state.openModal === "viewColumnPicker" &&
                    <TablePreferencePicker columnConfig={this.props.columnConfig} currentUser={this.props.currentUser} open={this.state.openModal === "viewColumnPicker"} target="toggleColumnPickerButton" setColumns={this.props.setColumns} refetchPreferences={this.props.refetchPreferences} preferenceType={this.props.preferenceType} />
                }
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} defaultValue={this.props.filterValues && this.props.filterValues.searchTerm} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={8} className="text-right mt-2">
                    <a className="driver-list-button" onClick={() => this.toggleModal("MassMessageModal")}>
                        Message Drivers | <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_insurance_rep") && this.props.selectedInsurances.length > 0 && <>
                        <a className="driver-list-button" onClick={this.unAssignReps}>
                            Unassign Reps | <i className="fa fa-minus" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="driver-list-button" onClick={() => this.toggleModal("RepAssignmentModal")}>
                            Assign Reps | <i className="fa fa-plus" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    </>}
                    <a id="toggleColumnPickerButton" className="driver-list-button" onClick={() => this.toggleModal("viewColumnPicker")}>
                        Preference | <i class="fa fa-table" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedInsurances && this.props.selectedInsurances.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedInsurances}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedInsurances.length}
                    </span>}
                </Col>
            </Row >
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["custom_can_edit_insurance_rep"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
)(InsuranceListHeader)