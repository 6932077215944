import React, { Component } from 'react'
import gql from "graphql-tag"
import { compose, graphql } from "react-apollo"
import { Container, Row, Col, Card, CardBody, Button, Nav, NavItem } from 'reactstrap'
import ReturnsList from "../CA/ReturnsList"
import '../CA/CarsList.css'
import moment from 'moment'
import { debounce } from 'lodash'
import RentalsListHeader from "../Rentals/RentalListNew/RentalsListHeader"

const tabs = [{ id: "#awaitingToCloseout", name: "Awaiting To Closeout", filters: { returnStage: [3], orderBy: ["-agreement__driver__in_office", "-id"] } }, { id: "#all", name: "All Returns", filters: { orderBy: ["-dateAdded", "-agreement__driver__in_office"] } }]
class CarReturnListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: null,
            searchTerm: "",
            selectedIds: [],
            filterValues: {
                orderBy: ["-dateAdded", "-agreement__driver__in_office"],
                returnStage: [3]
            },
            dateFilterType: "Scheduled Date",
            refetchReservations: false,
        }
    }
    updateOrder = (string) => {
        let order = [...this.state.filterValues.orderBy]
        if (!order.includes(string) && !order.includes("-" + string)) {
            order = [string, order.find(item => item.includes("-agreement__driver__in_office"))]
        } else {
            let index = order.indexOf(string)
            if (order.indexOf(string) > -1) {
                order[index] = "-" + string
            } else {
                index = order.indexOf("-" + string)
                index > -1 && (order[index] = string)
            }
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    }
    updateSelection = (selection) => {
        if (Array.isArray(selection)) {
            this.setState({ selectedIds: selection })
        } else {
            if (this.state.selectedIds.includes(selection))
                this.setState({ selectedIds: this.state.selectedIds.filter(item => item != selection) })
            else
                this.setState({ selectedIds: [...this.state.selectedIds, selection] })
        }
    }
    componentDidMount() {
        if (window.location.hash && tabs.map(item => item.id).includes(window.location.hash)) {
            let tabIndex = tabs.findIndex(tab => tab.id === window.location.hash)
            this.setState({ filterValues: { ...tabs[tabIndex].filters }, activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ filterValues: { ...tabs[0].filters }, activeTab: tabs[0].name })
        }
    }
    changeTab = (e) => {
        let name = e.target.name
        let value = e.target.value
        let tabIndex = tabs.findIndex(tab => tab.name === value)
        window.location.hash = tabs[tabIndex].id
        this.setState({ [name]: value, filterValues: { ...tabs[tabIndex].filters } })
    }
    render() {
        return (
            <Container fluid className="CarListContainer">
                <Nav pills className="available-car-wrap">
                    {tabs && tabs.length > 0 && tabs.map(tab =>
                        <NavItem key={tab.id}>
                            <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>
                                {tab.name && tab.name === "Awaiting To Closeout" ? <i className="fa fa-reply pr-3" aria-hidden="true"></i> : <i className="fa fa-calendar-o pr-3" aria-hidden="true"></i>}
                                {tab.name}
                            </Button>
                        </NavItem>
                    )}
                </Nav>
                <RentalsListHeader
                    activeTab={this.state.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    location={this.props.location} history={this.props.history}
                    defaultFilters={this.state.activeTab && tabs.find(tab => tab.name === this.state.activeTab) ? tabs.find(tab => tab.name === this.state.activeTab).filters : null}
                    selectedDrivers={this.state.selectedIds}
                    resetSelectedDrivers={() => this.setState({ selectedIds: [] })}
                    dateFilterType={this.state.dateFilterType}
                    updateDateFilterType={(dateFilterType) => this.setState({ dateFilterType })}
                />

                <Row className="pb-2">
                    <Col sm="12">
                        {(this.state.activeTab === "Awaiting To Closeout" || this.state.activeTab === "All Returns") &&
                            <ReturnsList
                                currentUser={this.props.currentUser}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                                selectedDrivers={this.state.selectedIds}
                                inOffice={this.state.filterValues && this.state.filterValues.inOffice !== null ? this.state.filterValues.inOffice : null}
                                driverStage={this.state.filterValues && this.state.filterValues.driverStage ? this.state.filterValues.driverStage : null}
                                returnType={this.state.filterValues && this.state.filterValues.returnType ? this.state.filterValues.returnType : null}
                                returnCategories={this.state.filterValues && this.state.filterValues.returnCategories ? this.state.filterValues.returnCategories : null}
                                agreementTypes={this.state.filterValues && this.state.filterValues.agreementTypes ? this.state.filterValues.agreementTypes : null}
                                updateDriversSelection={this.updateSelection}
                                orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                                returnStage={this.state.filterValues && this.state.filterValues.returnStage !== null ? this.state.filterValues.returnStage : null}
                                updateOrder={this.updateOrder}
                                scheduledDateGte={this.state.dateFilterType === "Scheduled Date" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                scheduledDateLte={this.state.dateFilterType === "Scheduled Date" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                rentalEndDateGte={this.state.dateFilterType === "Rental End Date" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                rentalEndDateLte={this.state.dateFilterType === "Rental End Date" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                dateAddedGte={this.state.dateFilterType === "Date Added" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                dateAddedLte={this.state.dateFilterType === "Date Added" && this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                dateFilterType={this.state.dateFilterType}
                                activeTab={this.state.activeTab ? this.state.activeTab : ""}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default compose(
)(CarReturnListContainer)
