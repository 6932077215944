import React, { Component } from "react"
import { compose, graphql } from "react-apollo"
import { withApollo } from "react-apollo/index"
import { AllPricingTemplates } from "./Queries"
import { Button, Table, Row, Col, UncontrolledTooltip, Badge, Card, CardBody } from "reactstrap"
import Progress from "reactstrap/lib/Progress"
import UpdatePopup from "./UpdatePopup"
import toTitleCase from "../Functions/toTitleCase"
import "../Material/ListingHeader.css"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
const tableHeaders = [
  { id: "title", name: "Details", sortable: true },
  { id: "car_model__name", name: "Car Model", sortable: true },
  { id: "is_default", name: "Is Default", sortable: true },
  { id: "is_active", name: "Is Active", sortable: true },
  { id: "interval", name: "Interval", sortable: false },
  { id: "price", name: "Default Price", sortable: false },
  { id: "priceRange", name: "Price Range", sortable: false },
  { id: "carYearMax", name: "Model Year Range", sortable: false },
  { id: "visibleToCustomers", name: "Customer Visibility", sortable: false },
]

class PricingTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      openModal: "",
      selectAll: false
    }
  }

  toggleModal = (name) => {
    this.setState({ openModal: this.state.openModal === name ? "" : name })
  };
  updateSelected = (value) => {
    let selectedPricing = this.props.selectedPricing
    if (selectedPricing.includes(value)) {
      selectedPricing = selectedPricing.filter(item => item !== value)
    } else {
      selectedPricing.push(value)
    }
    this.props.setSelectedPricing(selectedPricing)
  };
  toggleSelectAllClick = () => {
    if (this.props.drivers && !this.state.selectAll) {
      this.props.setSelectedPricing(this.props.allPricingTemplates.edges.map(n => n.node.id))
      this.setState({ selectAll: true })
    } else {
      this.props.setSelectedPricing([])
      this.setState({ selectAll: false })
    }
  };

  render() {
    return <>
      {/* {(this.props.loading || this.state.loading) && <Row><Col sm="12"><Progress animated color="info" value="100" /></Col></Row>}
      <Card className="prices-table-wrapper">
        <CardBody>
          <Row>
            <Col sm="12">
              <div className="table-container"> */}
      <PrimaryListingContainer listLoading={this.props.loading || this.state.loading} totalFetchedRows={this.props.allPricingTemplates && this.props.allPricingTemplates.edges.length} pageInfo={this.props.allPricingTemplates && this.props.allPricingTemplates.pageInfo} loadMoreEntries={this.props.allPricingTemplates && this.props.allPricingTemplates.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
        <Table responsive>
          <thead>
            <tr>
              <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
              {tableHeaders.map(header =>
                header.sortable ?
                  <th id={header.id} style={{ cursor: "pointer" }} onClick={() => this.props.updateOrder(header.id)}>{this.props.orderBy.some(order => order.includes(header.id)) ? <i className={"fa " + (this.props.orderBy.includes(header.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{header.name}</th> :
                  <th id={header.id}>{header.name}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.props.allPricingTemplates && this.props.allPricingTemplates.edges && this.props.allPricingTemplates.edges.length > 0 ? this.props.allPricingTemplates.edges.map((price, index) =>
              <tr key={price.node.id} id={price.node.id}>
                <td scope="row" className="listing-checkbox-wrapper">
                  {!this.props.selectedPricing.includes(price.node.id) && <span className="listing-counter">{index + 1}</span>}
                  <span className="listing-checkbox" onClick={() => this.updateSelected(price.node.id)}>
                    {this.props.selectedPricing.includes(price.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                  </span>
                </td>
                <td>
                  <small>
                    {price.node.agreementType ? price.node.agreementType.name : "--"}
                  </small>
                  <br />
                  <span>{price.node.title || "--"}</span>
                  &nbsp;&nbsp;{price.node.isDefault && <Badge color="primary">Default Price</Badge>}
                </td>
                <td>
                  {price.node.carModel && <>
                    <small>{price.node.carModel && price.node.carModel.groupType ? price.node.carModel.groupType.name : "--"}</small>
                    <br />
                    <span>{toTitleCase(price.node.carModel.make ? price.node.carModel.make : "") + " " + toTitleCase(price.node.carModel.name)}</span>{price.node.carModel.series && <> ({price.node.carModel.series})</>}
                  </>}
                </td>
                <td>
                  &nbsp;&nbsp;{price.node.isDefault ? <>
                    <i id={"priceIsDefault" + index} className="ml-2 fa fa-check text-success" />
                    <UncontrolledTooltip placement="right" target={"priceIsDefault" + index}>Default Price</UncontrolledTooltip>
                  </> : <>
                    <i id={"priceIsNotDefault" + index} className="ml-2 fa fa-times text-danger" />
                    <UncontrolledTooltip placement="right" target={"priceIsNotDefault" + index}>Not-Default Price</UncontrolledTooltip>
                  </>}
                  &nbsp;&nbsp;&nbsp;&nbsp;<i id={"isDefault" + index} onClick={() => this.toggleModal("isDefault" + index)} className={"fa fa-pencil"} />
                  {this.state.openModal === "isDefault" + index && <UpdatePopup target={"isDefault" + index} isOpen={this.state.openModal == "isDefault" + index} header="Update Template Is Default" dataId={price.node.id} value={price.node.isDefault} name="isDefault" type="checkbox" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("isDefault" + index)} />}
                </td>
                <td>
                  &nbsp;&nbsp;{price.node.isActive ? <>
                    <i id={"priceIsActive" + index} className="ml-2 fa fa-check text-success" />
                    <UncontrolledTooltip placement="right" target={"priceIsActive" + index}>Active Price</UncontrolledTooltip>
                  </> : <>
                    <i id={"priceIsNotActive" + index} className="ml-2 fa fa-times text-danger" />
                    <UncontrolledTooltip placement="right" target={"priceIsNotActive" + index}>In-Active Price</UncontrolledTooltip>
                  </>}
                  &nbsp;&nbsp;&nbsp;&nbsp;<i id={"isActive" + index} onClick={() => this.toggleModal("isActive" + index)} className={"fa fa-pencil"} />
                  {this.state.openModal === "isActive" + index && <UpdatePopup target={"isActive" + index} isOpen={this.state.openModal == "isActive" + index} header="Update Template Is Active" dataId={price.node.id} value={price.node.isActive} name="isActive" type="checkbox" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("isActive" + index)} />}
                </td>
                <td>
                  {price.node.interval} {price.node.intervalUnit}
                </td>
                <td>
                  ${price.node.price}&nbsp;&nbsp;<small><i id={"updatePrice" + index} onClick={() => this.toggleModal("updatePrice" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatePrice" + index && <UpdatePopup target={"updatePrice" + index} isOpen={this.state.openModal == "updatePrice" + index} header="Update Template Price" dataId={price.node.id} value={price.node.price} name="price" type="number" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("updatePrice" + index)} />}
                </td>
                <td>
                  ${price.node.minimumPrice}&nbsp;&nbsp;<small><i id={"updateMinimumPrice" + index} onClick={() => this.toggleModal("updateMinimumPrice" + index)} className={"fa fa-pencil"} /></small>
                  &nbsp;&#8212;&nbsp;${price.node.maximumPrice}&nbsp;&nbsp;<small><i id={"updatemaximumPrice" + index} onClick={() => this.toggleModal("updatemaximumPrice" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatemaximumPrice" + index && <UpdatePopup target={"updatemaximumPrice" + index} isOpen={this.state.openModal == "updatemaximumPrice" + index} header="Update Template Maximum Price" dataId={price.node.id} value={price.node.maximumPrice} name="maximumPrice" type="number" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("updatemaximumPrice" + index)} />}
                  {this.state.openModal === "updateMinimumPrice" + index && <UpdatePopup target={"updateMinimumPrice" + index} isOpen={this.state.openModal == "updateMinimumPrice" + index} header="Update Template Minimum Price" dataId={price.node.id} value={price.node.minimumPrice} name="minimumPrice" type="number" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("updateMinimumPrice" + index)} />}
                </td>
                <td>
                  {price.node.carYearMin}&nbsp;&nbsp;<small><i id={"updatecarYearMin" + index} onClick={() => this.toggleModal("updatecarYearMin" + index)} className={"fa fa-pencil"} /></small>
                  &nbsp;&#8212;&nbsp;{price.node.carYearMax}&nbsp;&nbsp;<small><i id={"updatecarYearMax" + index} onClick={() => this.toggleModal("updatecarYearMax" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "updatecarYearMax" + index && <UpdatePopup target={"updatecarYearMax" + index} isOpen={this.state.openModal == "updatecarYearMax" + index} header="Update Template Car Year Maximum" dataId={price.node.id} value={price.node.carYearMax} name="carYearMax" type="number" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("updatecarYearMax" + index)} />}
                  {this.state.openModal === "updatecarYearMin" + index && <UpdatePopup target={"updatecarYearMin" + index} isOpen={this.state.openModal == "updatecarYearMin" + index} header="Update Template Car Year Minimum" dataId={price.node.id} value={price.node.carYearMin} name="carYearMin" type="number" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("updatecarYearMin" + index)} />}
                </td>
                <td>
                  {price.node.visibleToCustomers ? "True" : "False"}&nbsp;&nbsp;<small><i id={"visibleToCustomers" + index} onClick={() => this.toggleModal("visibleToCustomers" + index)} className={"fa fa-pencil"} /></small>
                  {this.state.openModal === "visibleToCustomers" + index && <UpdatePopup target={"visibleToCustomers" + index} isOpen={this.state.openModal == "visibleToCustomers" + index} header="Update Template Is visible to Customer" dataId={price.node.id} value={price.node.visibleToCustomers} name="visibleToCustomers" type="checkbox" refetchAllPricingTemplates={() => this.props.refetchQuery()} handleClose={() => this.toggleModal("visibleToCustomers" + index)} />}
                </td>
              </tr>
            ) : <tr> <td colSpan={20}>
              <p className="column-text">{this.props.loading ? "Loading  Prices..." : "No Templates Found!"}</p>
            </td></tr>
            }
          </tbody>
        </Table>
      </PrimaryListingContainer>
      {/* </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div>
        <Button disabled={this.props.loading} className="float-left mt-2 btn btn-secondary" onClick={() => this.props.refetchQuery()}>Reload &nbsp;&nbsp;<i className="fa fa-refresh"></i></Button>
        {this.props.allPricingTemplates && this.props.allPricingTemplates.pageInfo && this.props.allPricingTemplates.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} className="float-right mt-2 btn btn-primary-showmore-outline">Show More <i className={"fa fa-caret-down"}></i></Button>}
      </div> */}
    </>
  }
}

export default compose(
  withApollo,
  graphql(AllPricingTemplates, {
    options: ({ modelGroupTypeId, modelTypeIds, agreementTypeId, isActive, isDefault, searchTerm, orderBy }) => ({
      variables: { modelGroupTypeId, modelTypeIds, agreementTypeId, isActive, isDefault, first: 30, orderBy, searchTerm },
      fetchPolicy: "cache-and-network",
    }), props({ data: { loading, allPricingTemplates, fetchMore, variables } }) {
      return {
        loading, allPricingTemplates, variables,
        refetchQuery: () => {
          //No apollo function that refetches in place with pagination considered so this function instead
          let currentLength = allPricingTemplates && allPricingTemplates.edges ? allPricingTemplates.edges.length : 30
          return fetchMore({
            query: AllPricingTemplates,
            variables: {
              ...variables,
              first: currentLength,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.allPricingTemplates.edges
              const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo
              return {
                allPricingTemplates: {
                  edges: [...newEdges],
                  pageInfo,
                  searchTerm: previousResult.searchTerm,
                  __typename: previousResult.allPricingTemplates.__typename
                },
              }
            },
          })
        },

        loadMoreEntries: () => {
          return fetchMore({
            query: AllPricingTemplates,
            variables: {
              cursor: allPricingTemplates.pageInfo.endCursor,
              ...variables,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.allPricingTemplates.edges
              const pageInfo = fetchMoreResult.allPricingTemplates.pageInfo

              return {
                allPricingTemplates: {
                  edges: [
                    ...previousResult.allPricingTemplates.edges,
                    ...newEdges,
                  ],
                  pageInfo,
                  status: previousResult.status,
                  __typename: previousResult.allPricingTemplates.__typename,
                },
              }
            },
          })
        },
      }
    },
  })
)(PricingTable)

