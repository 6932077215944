import gql from "graphql-tag"

const TransactionsQuery = gql`query TransactionsQuery($cursor: String,$first:Int!,$driverId:ID!,$chargeTypeIds:[ID],$groupTypeIds:[ID],$includeDeleted:Boolean,$minAmount:Float,$maxAmount:Float,$startDate:Date,$endDate:Date,$notes:String, $orderBy:[String], $includeUpdates:Boolean, $loadDynamicValues: Boolean!) {
    transactions: allTransactions(first:$first,after: $cursor,driverId:$driverId,chargeTypeIds:$chargeTypeIds,groupTypeIds:$groupTypeIds,includeDeleted:$includeDeleted,minAmount:$minAmount,maxAmount:$maxAmount,startDate:$startDate,endDate:$endDate,notes:$notes, orderBy: $orderBy, includeUpdates:$includeUpdates){
        edges{
            node{
                id
                pk
                dateAdded
                dueDate
                amount
                dynamicAmount @include(if: $loadDynamicValues)
                dynamicBalance @include(if: $loadDynamicValues)
                eventDatetime
                notes
                balance
                isDeleted
                updateFor {
                    id
                    updateForPK: pk
                }
                chargeType{
                    id
                    name
                }
                groupType{
                    id
                    name
                }
                agreement{
                    id
                    startDate
                    endDate
                    car {
                        id
                        carPK: pk
                    } 
                }
                status
                createdBy{
                    id
                    username
                }
                branch{
                    id
                    name
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const AllChargeTypes = gql`query AllChargeTypes{
    allChargeTypes(isActive:true,orderBy:["name"]){
        edges {
            node {
            id
            name
            category
            }
        }
        }
}`

const AllGroupTypes = gql`query AllGroupTypes{
    allGroupTypes(isActive:true,orderBy:["name"]){
        edges {
        node {
            id
            name
        }
        }
    }
}`

export { TransactionsQuery, AllChargeTypes, AllGroupTypes }
