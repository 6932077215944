import React, { useState, useEffect } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Nav, NavItem, NavLink, Container, Badge, TabContent, TabPane } from 'reactstrap'
import Loader from "../Material/Loader"
import OverviewTab from "./OverviewTab"
import { UserDetailQuery } from "./Queries"

const MEMBER_DETAIL_PAGE_TABS = [
    { id: "#overview", name: "OVERVIEW" },
]

function UserDetailContainer({ loading, currentUser, refetchUserQuery, id }) {
    const [activeTab, setActiveTab] = useState("overview")
    const [rendererdTabPanes, setRenderedTabPanes] = useState([])

    useEffect(() => {
        let defaultTab = MEMBER_DETAIL_PAGE_TABS[0].id

        if (window.location.hash && MEMBER_DETAIL_PAGE_TABS.map(item => item.id).includes(window.location.hash)) {
            setActiveTab(window.location.hash)
            setRenderedTabPanes([window.location.hash])
        } else {
            window.location.hash = defaultTab
            setActiveTab(defaultTab)
            setRenderedTabPanes([defaultTab])
        }
    }, [])

    useEffect(() => {
        window.location.hash = activeTab
    }, [activeTab])

    const toggleTab = (tabId) => {
        if (!rendererdTabPanes.includes(tabId)) {
            setRenderedTabPanes([...rendererdTabPanes, tabId])
        }
        setActiveTab(tabId)
    }

    return (
        <Container fluid className="driver-detail-container">
            {loading ? <Loader /> : currentUser && <>
                <div className="driver-header">
                    <div className="driver-image">
                        <i className="fa fa-2x fa-user" aria-hidden="true"></i>
                    </div>
                    <div className="driver-name">
                        <label>
                            {currentUser && currentUser.username ? currentUser.username : ""}{" "}
                            {currentUser.isActive ? <i className="fa fa-check-circle fa-lg text-success" aria-hidden="true" /> : <i className="fa fa-times-circle fa-2x text-danger" aria-hidden="true" />}
                            &nbsp;&nbsp;
                        </label>
                    </div>
                </div>
                <div className="driver-detail">
                    <Nav tabs>
                        {MEMBER_DETAIL_PAGE_TABS.map(item =>
                            <NavItem key={item.id} className={activeTab === item.id ? "active" : "inactive"}>
                                <NavLink className={activeTab === item.id ? "active" : "inactive"} onClick={() => toggleTab(item.id)}>
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {rendererdTabPanes && rendererdTabPanes.includes("#overview") && <TabPane tabId="#overview">
                            <div className="bos-object-nav-content">
                                <OverviewTab id={id} toggleTab={(id) => toggleTab(id)} currentUser={currentUser} refetchQuery={refetchUserQuery} />
                            </div>
                        </TabPane>}
                    </TabContent>
                </div>
            </>
            }
        </Container>
    )
}

export default compose(
    withApollo,
    graphql(UserDetailQuery, {
        options: () => ({
            fetchPolicy: 'cahce-and-network',
        }), props({ data: { currentUser, loading, variables, refetch } }) {
            return {
                currentUser,
                loading,
                variables,
                refetchUserQuery: () => {
                    return refetch({
                        query: UserDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { currentUser: fetchMoreResult.currentUser }
                        },
                    })
                },
            }
        }
    }),
)(UserDetailContainer)