import React, { Component, Fragment } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Row, Label, Nav, NavItem, NavLink, Card, Badge, Button } from 'reactstrap'
import Loader from "../../../Material/Loader"
import "react-awesome-lightbox/build/style.css"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import DocumentModalNew from "../../../Material/DocumentModal/DocumentModalNew"
import CarIssueModal from "../../../Maintenance/CarIssueModal"
import { cleanDocumentUrl } from "../../../Material/DocumentModal/DocumentsListNew"
const carConditionDetail = gql`query carCondition($id: ID!){
    carCondition(carConditionId:$id){
        id
        pk
        frontPic
        rearPic
        driverSidePic
        formData
        headlightsWorking
        seatCovers
        gas
        dateAdded
        dateModified
        mileage
        user{
          id
          username
        }
        carissuesSet {
          edges {
            node {
              id
              pk
              canPickup
              issueCategory{
                id
                category
              }
              status
              reportedBy
              dateAdded
              metadata
            }
          }
        }
        carpicturesSet {
          edges {
            node {
              id
              pictureUrl
              pictureType
              description
              dateAdded
            }
          }
        }
      
    }     
}`

class CarInspectionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            pictiresSetSubsection: "carsPictureSet",
            carInspectionSubsection: "inspectionDetails",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    viewPictures = (object, pictureType, index = 0) => {
        let pictures = []
        if (pictureType !== "exterior walkaround") {
            let metadata = JSON.parse(object.metadata.replace(/'/g, '"'))
            if (metadata && metadata['issue_pictures']) {
                pictures = [...pictures, ...metadata['issue_pictures'].map((picture, index) => ({ ...picture, documentUrl: cleanDocumentUrl(picture.picture_url), description: picture.description, name: picture.picture_type, id: object.id + picture.picture_type + picture.issue_category_name + index, dateAdded: object.dateAdded }))]
            }
        } else {
            pictures = object.filter(picture => picture.node.pictureType.toLowerCase() === "exterior walkaround").map(picture => ({ documentUrl: cleanDocumentUrl(picture.node.pictureUrl), id: picture.node.id, name: picture.node.pictureType, description: picture.node.description, dateAdded: picture.node.dateAdded }))
        }

        this.setState({ carPictures: pictures, selectedImage: pictures[index] })
    }

    pObj = (jsonString) => {
        let obj = JSON.parse(jsonString)
        return obj
    }
    next = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === carPictures.length - 1) return
        this.setState({ selectedImage: carPictures[currentIndex + 1] })
    }

    prev = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: carPictures[currentIndex - 1] })
    }

    renderPicturesInfo = (carIssue) => {
        let metadata = JSON.parse(carIssue.node.metadata.replace(/'/g, '"'))
        let issuePictures = metadata['issue_pictures']
        return (
            issuePictures && issuePictures.length > 0 ?
                <div onClick={() => this.viewPictures(carIssue.node, "carIssue")}>
                    <i className="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
                    {issuePictures.length > 1 &&
                        <a className="bos-custom-link" target="_blank">
                            {" "}+{issuePictures.length - 1} more
                        </a>}
                </div> :
                "No Pictures Found"
        )
    }

    render() {
        const IssuesTable = [
            { id: "date_addded", name: "DATE ADDED", sortable: true },
            { id: "issueCategory", name: "Category", sortable: false },
            { id: "status", name: "Status", sortable: false },
            { id: "reportedBy", name: "Reported By", sortable: false },
            { id: "canPickup", name: "Can Pickup", sortable: false },
            { id: "pictures", name: "Pictures", sortable: false },
        ]
        return (
            <>
                <Col className="zero-padding">
                    {this.state.selectedImage &&
                        <DocumentModalNew
                            document={this.state.selectedImage}
                            handleClose={() => this.setState({ selectedImage: null })}
                            next={this.next}
                            prev={this.prev}
                            objectType={"car"}
                            isPicture={true}
                            refetch={this.props.refetch}
                            currentUser={this.props.currentUser}
                        />
                    }
                    {this.props.loading ? <Loader /> : <>
                        <div className="inspection-detail">
                            <Nav tabs>
                                <NavItem className={this.state.tab === "inspectionDetails" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "inspectionDetails" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "inspectionDetails")}>
                                        INSPECTION DETAILS
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "carIssue" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "carIssue" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "carIssue")}>
                                        CAR ISSUES
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div className="info-container" style={{ margin: "0px", borderTopLeftRadius: "0px" }}>
                                <Row>
                                    <Col>
                                        {this.props.carCondition ?
                                            this.state.carInspectionSubsection === "inspectionDetails" ?
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <Label>Date Added</Label>
                                                            <p>{this.props.carCondition.dateAdded ? <DatetimeRenderer datetime={this.props.carCondition.dateAdded} /> : "--"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Date Modified</Label>
                                                            <p>{this.props.carCondition.dateModified ? <DatetimeRenderer datetime={this.props.carCondition.dateModified} /> : "--"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Inspection Type</Label>
                                                            <p>
                                                                {this.props.carCondition.formData && this.pObj(this.props.carCondition.formData) && "form_type" in this.pObj(this.props.carCondition.formData) ? this.pObj(this.props.carCondition.formData)["form_type"] : "---"}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Label>User</Label>
                                                            <p className="columnContent">
                                                                {this.props.carCondition.user ? this.props.carCondition.user.username : "---"}
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Gas</Label>
                                                            <p className="columnContent">{this.props.carCondition.gas}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Mileage</Label>
                                                            <p className="columnContent">{this.props.carCondition.mileage}</p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        {IssuesTable.map(tableHeader =>
                                                                            <th key={tableHeader.id}>{tableHeader.name}</th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.props.carCondition && this.props.carCondition.carissuesSet && this.props.carCondition.carissuesSet.edges ? this.props.carCondition.carissuesSet.edges.map((carIssue, i) =>
                                                                        <tr>
                                                                            {this.state.openModal && this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={carIssue.node} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.props.refetchQuery} />}
                                                                            <td>{carIssue.node.dateAdded ? <DatetimeRenderer datetime={carIssue.node.dateAdded} /> : "---"}</td>
                                                                            <td>
                                                                                {carIssue.node.issueCategory ?
                                                                                    <a className="bos-custom-link" onClick={() => this.toggleModal("editCarIssue" + i)} target="_blank">
                                                                                        {carIssue.node.issueCategory.category}
                                                                                    </a>
                                                                                    : "---"}
                                                                            </td>
                                                                            <td>{carIssue.node.status ? carIssue.node.status : "---"}</td>
                                                                            <td>{carIssue.node.reportedBy ? carIssue.node.reportedBy : "---"}</td>
                                                                            <td>{carIssue.node.canPickup ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td>
                                                                            <td>
                                                                                {carIssue.node.metadata ? this.renderPicturesInfo(carIssue) : "No Pictures Found"}

                                                                            </td>
                                                                        </tr>)
                                                                        : <tr><td>No Car Issues Found</td></tr>}
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            : <p style={{ color: "red" }}>No Car Condition Found!</p>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Nav tabs>
                                <NavItem className={this.state.tab === "carsPictureSet" ? "active" : "inactive"}>
                                    <NavLink className={this.state.pictiresSetSubsection === "carsPictureSet" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("pictiresSetSubsection", "Driver Detail")}>
                                        Walkaround Pictures
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                <Row>
                                    <Col>
                                        <Fragment>
                                            <div className="DocumentCardContainer" >
                                                {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() === "exterior walkaround").map((document, index) =>
                                                    <Card className="DocumentCard" key={index}>
                                                        <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges, "exterior walkaround", index)}>
                                                            <p className="DocumentCardTitle">
                                                                <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                <span>{document.node.description}</span>
                                                            </p>
                                                            <p><i className="fa fa-arrows-alt"></i></p>
                                                        </div>
                                                        <div className="DocumentCardPreview" >
                                                            {document.node ? (

                                                                <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                            ) : ''}
                                                        </div>
                                                    </Card>
                                                )}
                                            </div >
                                        </Fragment>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>}
                </Col>
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(carConditionDetail, {
        options: ({ id }) => ({ variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { carCondition, loading, refetch, variables } }) {
            return {
                carCondition, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: carConditionDetail,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { carCondition: fetchMoreResult.carCondition }
                        },
                    })
                },
            }
        }
    }),
)(CarInspectionDetail)