import gql from 'graphql-tag'

export const AllAutomationsQuery = gql`query AllAutomations($first: Int, $after: String, $searchTerm: String, $isActive: Boolean){
    allAutomations(first: $first, after: $after, name_Icontains: $searchTerm, isActive: $isActive){
        edges {
        node {
            id
            name
            dateAdded
            isActive
            actionSet{
                edges{
                    node{ actionType }
                }
            }
            triggerSet{
                edges{
                    node { triggerType contentType { model }  }              
                }
            }
            createdBy{
                username
            }
        }
        }
    }
}`

export const AllAvailableModels = gql`query AllAvailableModels($first: Int, $after: String){
    allAvailableModels(first: $first, after: $after){
        edges{
            node{ id availableModelFields recentObjects contentType{ appLabel model } }
        }
    }
    
}`


export const AllAvailableModelFields = gql`query AllAvailableModelFields($id: ID!){
    allAvailableModelFields(id: $id)
}`


export const ActionTypesAndParams = gql`query ActionTypesAndParams{
    actionTypesAndParams{
        actionType
        actionParams
    }
}`

export const ConditionTypes = gql`query ConditionTypes{
    conditionTypes: __type(name: "ConditionConditionType") {
        states: enumValues {
            name
            description
        }
    }
}`

export const TriggerTypes = gql`query TriggerTypes{
    triggerTypes: __type(name: "TriggerTriggerType") {
        states: enumValues {
            name
            description
        }
    }
}`
